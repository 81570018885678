import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import NoDataIcon from 'assets/jsx-icon/NoDataIcon';
import React from 'react';
import color from 'theme/Colors';

interface Props {
    sx?: SxProps<Theme>;
    title?: string;
}

export default function ListNoData(props: Props) {
    return (
        <Stack
            width={'100%'}
            direction={'column'}
            justifyContent={'center'}
            minHeight={'200px'}
            padding={'16px 12px'}
            bgcolor={color.white}
            sx={{ borderRadius: '8px', ...props.sx }}
        >
            <Stack direction={'column'} alignItems={'center'}>
                <NoDataIcon />
                <Typography sx={{ color: color.grey600 }} textAlign={'center'}>
                    No data here
                </Typography>
            </Stack>
        </Stack>
    );
}
