import React from 'react';

export default function PasswordLockIcon() {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 9.37976C16.6569 9.37976 18 10.7229 18 12.3798V18.3798C18 20.0367 16.6569 21.3798 15 21.3798H3C1.34315 21.3798 0 20.0367 0 18.3798V12.3798C0 10.7229 1.34315 9.37976 3 9.37976V6.37976C3 3.06605 5.68629 0.379761 9 0.379761C12.3137 0.379761 15 3.06605 15 6.37976V9.37976ZM9 2.37976C11.2091 2.37976 13 4.17062 13 6.37976V9.37976H5V6.37976C5 4.17062 6.79086 2.37976 9 2.37976ZM15 11.3798H3C2.44772 11.3798 2 11.8275 2 12.3798V18.3798C2 18.9321 2.44772 19.3798 3 19.3798H15C15.5523 19.3798 16 18.9321 16 18.3798V12.3798C16 11.8275 15.5523 11.3798 15 11.3798Z"
                fill="#667085"
            />
        </svg>
    );
}
