import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { FormValue } from 'components/header/MyAppBar';
import { IPopUp } from 'components/hooks/usePopUp';
import PopUpBase from 'components/PopUpBase';
import { pushError, pushSuccess } from 'components/toast';
import { shopController } from 'controllers';
import React, { useState } from 'react';
import {
    Control,
    FieldErrors,
    UseFormClearErrors,
    UseFormHandleSubmit,
    UseFormReset,
    UseFormWatch,
} from 'react-hook-form';
import color from 'theme/Colors';

type Props = Omit<IPopUp, 'onConfirm'> & {
    reset: UseFormReset<FormValue>;
    watch: UseFormWatch<FormValue>;
    handleSubmit: UseFormHandleSubmit<FormValue, undefined>;
    popUpCreateNewShopLink: {
        setTrue: () => void;
        open: boolean;
        onClose: () => void;
        onConfirm: (() => void) | undefined;
    };
};

export default function PopUpConfirmCreateNewLinkShop(props: Props) {
    const { open, onClose, reset, watch, popUpCreateNewShopLink, handleSubmit } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCreateNewLink = async (data: FormValue) => {
        setIsLoading(true);

        await shopController
            .createShopWithLink(data.createNewShop, data.createLinkRedirect ?? '')
            .then((res) => {
                pushSuccess('Create new link successfully');
                popUpCreateNewShopLink.onClose?.();
                reset({});
                props.onClose?.();
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <PopUpBase
            open={open}
            title={<Typography variant="h4">Confirm</Typography>}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'sm',
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                },
            }}
            onClose={onClose}
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack
                    sx={{
                        backgroundColor: color.white,
                        borderRadius: '8px',
                        padding: '4px 2px',
                    }}
                    direction={'column'}
                >
                    <Stack
                        sx={{
                            backgroundColor: color.grey50,
                            borderRadius: '8px',
                            padding: '12px 12px',
                        }}
                        direction={'column'}
                        spacing={2}
                    >
                        <Grid container>
                            <Grid item xs={12} sm={4} mt={1}>
                                <Typography fontWeight={500}>Your link:</Typography>
                            </Grid>
                            <Grid item xs={10} md={8} mt={1}>
                                <Typography>{watch().createNewShop}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={4} mt={1}>
                                <Typography fontWeight={500}>Link redirect:</Typography>
                            </Grid>
                            <Grid item xs={10} md={8} mt={1}>
                                <Typography>{watch().createLinkRedirect}</Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        disabled={isLoading}
                        onClick={() => {
                            props.onClose?.();
                            popUpCreateNewShopLink.setTrue();
                            reset({
                                createNewShop: watch().createNewShop,
                                createLinkRedirect: watch().createLinkRedirect,
                            });
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{
                            justifyContent: isLoading ? 'space-between' : 'center',
                            borderRadius: '8px',
                        }}
                        loading={isLoading}
                        type="submit"
                        loadingPosition="center"
                        disabled={watch().createNewShop ? false : true}
                        startIcon={isLoading ? <></> : undefined}
                        onClick={handleSubmit(async (data: FormValue) => {
                            await handleCreateNewLink(data);
                        })}
                        variant="contained"
                    >
                        Confirm
                    </LoadingButton>
                </Stack>
            }
        />
    );
}
