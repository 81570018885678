import React from 'react';
import color from 'theme/Colors';

export default function ArrowBackIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.875 14.2756L6.125 14.2756"
                stroke={color.textPrimary}
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14 22.1506L6.125 14.2756L14 6.40064"
                stroke={color.textPrimary}
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
