import moment from 'moment';
import momentTimeZone from 'moment-timezone';

export default function GetBooleanFromEnv(string: string) {
    return string === 'true';
}

export const formatUKDateTime = (date?: Date) => {
    if (!date) return 'Not available';
    return moment(date).tz(moment.tz.guess()).format('DD/MM/YYYY HH:mm:ss');
};
export const formatDateByTimeZone = (date: Date, timezone?: string, formatSS?: boolean) => {
    const _format: string = formatSS ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY HH:mm';
    return moment(date)
        .tz(timezone ?? moment.tz.guess())
        .format('DD/MM/YYYY HH:mm:ss');
};

export const formatUKDate = (date: Date) => {
    return moment(date).format('DD/MM/YYYY');
};
export const formatTime = (date: Date) => {
    return moment(date).format('DD/MM/YYYY HH:mm');
};
export const formatDateTime = (date: Date) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
};
export const getFirstAndLastDayOfCurrentMonth = () => {
    const currentDate = moment();

    const firstDayOfMonth = currentDate.clone().startOf('month');
    const lastDayOfMonth = currentDate.clone().endOf('month');

    return {
        from: firstDayOfMonth.toDate(),
        to: lastDayOfMonth.toDate(),
    };
};

export const getAvatarFromJira = (idxAvatar: number) => {
    return `https://intelli-park.atlassian.net/rest/api/2/universal_avatar/view/type/project/avatar/1040${idxAvatar}`;
};
