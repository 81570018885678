import { Backdrop, Stack } from '@mui/material';
import CircularLoading from 'components/CircularLoading';
import { ReactNode, createContext, useContext, useState } from 'react';

const useBackdropContext = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<ReactNode>();

    const setTrue = (message?: ReactNode) => {
        setOpen(true);
        setMessage(message);
        document.body.style.overflow = 'hidden';
    };
    const setFalse = () => {
        setOpen(false);
        document.body.style.overflow = '';
    };

    return {
        open,
        message,
        setTrue,
        setFalse,
    };
};

export const BackdropContext = createContext<ReturnType<typeof useBackdropContext>>({} as any);
export const useBackdrop = () => useContext(BackdropContext);

type Props = {
    children?: ReactNode;
};

const BackdropProvider = (props: Props) => {
    const backdrop = useBackdropContext();

    return (
        <BackdropContext.Provider value={backdrop}>
            {props.children}
            <Backdrop
                open={backdrop.open}
                sx={{ zIndex: 2000, backgroundColor: 'unset', backdropFilter: 'blur(1px) grayscale(1)' }}
            >
                <Stack alignItems={'center'} spacing={2}>
                    <CircularLoading isHeight />
                    {backdrop.message}
                </Stack>
            </Backdrop>
        </BackdropContext.Provider>
    );
};

export default BackdropProvider;
