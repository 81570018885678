import React from 'react';

export default function MailIcon() {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.00976992 1.71765C0.00976992 1.16537 0.45748 0.717651 1.00977 0.717651H17C17.5523 0.717651 18 1.16537 18 1.71765V13.0419C18 14.1465 17.1046 15.0419 16 15.0419H2C0.89543 15.0419 0 14.1465 0 13.0419V2.04187C0 1.99425 0.00332992 1.94741 0.00976992 1.90156V1.71765ZM2 3.94141V13.0419H16V3.94175L11.1215 8.82026C9.9499 9.99186 8.0504 9.99186 6.87885 8.82026L2 3.94141ZM3.57232 2.6853H14.428L9.7073 7.40606C9.3168 7.79656 8.6836 7.79656 8.2931 7.40606L3.57232 2.6853Z"
                fill="#667085"
            />
        </svg>
    );
}
