import { BaseHttpController } from '@Core/controller/BaseHttpController';

import { IAccountHttpController } from '../IAccountHttpController';
import { AxiosInstance } from 'axios';
import { Account, RegisterProps } from '../../models/Account';
import { Shop } from '../../models/Shop';
import { FbAuth } from '../../models/FacebookConnection';
import { PostContent, PostContentWithFiles, PostCount } from '../../models/PostContent';

export class PostContentHttpController extends BaseHttpController<PostContent> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'post-content', client);
    }
    async upsertPost(props: FormData): Promise<PostContent[]> {
        return await this.doPost({ path: 'upsert-post', body: props }).then((res) => res.data);
    }

    async countPostSuccess(): Promise<PostCount> {
        return await this.doGet({ path: 'count-post' }).then((res) => res.data);
    }
}
