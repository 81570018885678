import Login from 'pages/loginPage';
import IntermediateLink from 'pages/redirectLinkPage';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import AuthLayout from './layout/AuthLayout';
import ShopInfoPage from './pages/myShopPage';
import PostContentPage from 'pages/contentPage/PostContentPage';
import ListContentPage from 'pages/contentPage/ListContentPage';
import FacebookManagement from 'pages/facebook';
import PageNotFound from 'pages/pageNotFound';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthLayout />,
        children: [
            {
                index: true,
                element: <Navigate to={'my-shop'} />,
            },
            {
                path: 'my-shop',
                element: <ShopInfoPage />,
            },
            {
                path: 'post-content',
                element: <PostContentPage />,
            },
            {
                path: 'list-content',
                element: <ListContentPage />,
            },
            {
                path: 'facebook-management',
                element: <FacebookManagement />,
            },
        ],
    },
    { path: '/sign-in', element: <Login /> },
    { path: '/connect', element: <Login /> },
    { path: '/register/:linkCode', element: <Login /> },
    { path: '/register', element: <Login /> },

    { path: '/review/:code', element: <IntermediateLink /> },

    { path: '/page-not-found', element: <PageNotFound /> },
    { path: '*', element: <Navigate to={'/page-not-found'} /> },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
