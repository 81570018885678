import { Button, Stack, Typography } from '@mui/material';
import ArrowBackIcon from 'assets/jsx-icon/ArrowBackIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import color from 'theme/Colors';

export default function BackToNailSupplier() {
    const navigate = useNavigate();
    return (
        <Stack position={'absolute'} top={10} left={'5%'} direction={'row'} spacing={1.5} alignItems={'center'}>
            <Button
                onClick={() => navigate('/my-shop')}
                sx={{
                    borderRadius: 1,
                    backgroundColor: 'transparent',
                    border: `2px dashed ${color.appBarColor}`,
                    p: '10px',
                    minWidth: 0,
                    '&:hover': {
                        backgroundColor: 'transparent',
                        border: `2px dashed ${color.containedButtonBg}`,
                    },
                }}
            >
                <ArrowBackIcon />
            </Button>

            <Stack direction={'column'}>
                <Typography variant="h3">Nails supplier</Typography>
                <Typography variant="body2">Go back</Typography>
            </Stack>
        </Stack>
    );
}
