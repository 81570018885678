import { Divider, Grid, Stack, Typography } from '@mui/material';
import CircularLoading from 'components/CircularLoading';
import { DrawerPage, useDrawerContext } from 'components/drawer/useDrawer';
import { pushError } from 'components/toast';
import { postContentHttpController } from 'controllers';
import { useAuth } from 'hook/useAuth';
import useMedia from 'hook/useMedia';
import { useEffect, useState } from 'react';
import { PostCount } from 'submodules/nail-supplier-base-model/models/PostContent';
import color from 'theme/Colors';
import ButtonFacebookConnect from './components/ButtonFacebookConnect';
import PageNotYetConnected from './components/PageNotYetConnected';

export default function FacebookManagement() {
    const { tokenFB, getNamePageFromFBDev, dataFromFbDev } = useAuth();

    const [totalPosts, setTotalPosts] = useState<PostCount>({} as PostCount);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isMediumMD } = useMedia();
    const drawerContext = useDrawerContext();
    const { setActiveItem } = drawerContext;

    useEffect(() => {
        setActiveItem(DrawerPage.FacebookManagement);
        getNamePageFromFBDev(tokenFB);
        getTotalPosts();
    }, []);

    const getTotalPosts = async () => {
        setIsLoading(true);
        await postContentHttpController
            .countPostSuccess()
            .then((res) => {
                setTotalPosts(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const dataInformation = [
        {
            title: 'Total posts',
            value: totalPosts.totalPosts,
        },
        {
            title: 'Total posts for current week',
            value: <>{totalPosts.totalPostsForCurrentWeek} / 3</>,
        },
    ];

    return (
        <>
            {tokenFB ? (
                <Stack direction="column" justifyContent={'space-between'} mb={3} width={'100%'}>
                    <Stack
                        my={isMediumMD ? 2 : 4}
                        alignItems={'center'}
                        direction={'row'}
                        width={'100%'}
                        justifyContent={'space-between'}
                    >
                        <Stack>
                            <Typography
                                sx={{
                                    lineHeight: 2,
                                }}
                                variant="h2"
                            >
                                Facebook
                            </Typography>
                        </Stack>
                        <Stack>
                            <ButtonFacebookConnect isCheckUI={true} />
                        </Stack>
                    </Stack>

                    {isLoading ? (
                        <CircularLoading isHeight />
                    ) : (
                        <Stack
                            direction={'column'}
                            width={'100%'}
                            alignItems={'center'}
                            my={3}
                            borderRadius={'12px'}
                            padding={2}
                            spacing={isMediumMD ? 2 : 3}
                            justifyContent={'space-between'}
                            bgcolor={color.white}
                            sx={{
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                            }}
                        >
                            <Stack spacing={2} direction={'row'} alignItems={'center'} width={'100%'}>
                                <img
                                    src={dataFromFbDev?.picture?.data?.url}
                                    alt=""
                                    style={{
                                        borderRadius: '50%',
                                    }}
                                    width={48}
                                />
                                <Stack>
                                    <Typography variant="h4" textAlign={'left'}>
                                        {dataFromFbDev?.name ?? ''}
                                    </Typography>
                                    <Typography textAlign={'left'} variant="body2" color={color.secondary}>
                                        {dataFromFbDev?.category ?? ''}
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Divider orientation="horizontal" flexItem color={color.white} sx={{ display: 'flex' }} />

                            <Stack
                                width="100%"
                                mt={5}
                                gap={{ xs: 3, md: 0 }}
                                bgcolor={color.grey50}
                                borderRadius={1}
                                padding={'8px 16px'}
                            >
                                <Grid container>
                                    {dataInformation.map((total, index) => {
                                        return (
                                            <Grid item xs={6} key={index}>
                                                <Stack direction={'column'} spacing={1} alignItems={'center'}>
                                                    <Typography textAlign={'center'} variant="body2">
                                                        {total.title}:
                                                    </Typography>
                                                    <Typography
                                                        textAlign={'center'}
                                                        variant="h3"
                                                        lineHeight={1}
                                                        color={color.success}
                                                    >
                                                        {total.value}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            ) : (
                <PageNotYetConnected />
            )}
        </>
    );
}
