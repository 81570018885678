import { BaseHttpController } from '@Core/controller/BaseHttpController';

import { IAccountHttpController } from '../IAccountHttpController';
import { AxiosInstance } from 'axios';
import { Account } from '../../models/Account';
import { Shop } from '../../models/Shop';
import { IShopHttpController } from '../IShopHttpController';
import { ILinkReviewHttpController } from '../ILinkReviewHttpController';
import { LinkReview } from '../../models/LinkReview';

export class LinkReviewHttpController extends BaseHttpController<LinkReview> implements ILinkReviewHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'link-review', client);
    }
    async getList(code: string): Promise<Shop> {
        return await this.doGet({ path: `${code}` }).then((res) => res.data);
    }
    async upsertLink(t: Partial<LinkReview>): Promise<LinkReview[]> {
        return await this.doPost({ path: 'upsertLink', body: t }).then((res) => res.data);
    }
    async delete(id: number) {
        return await this.doDelete({ path: `${id}` }).then((res) => res.data);
    }
}
