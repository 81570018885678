const color = {
    primary: '#53389E',
    secondary: '#667085',

    textPrimary: '#292929',
    textSecondary: '#090F24',
    priText: '#7F56D9',

    lightPrimary: '#F4EBFF',
    darkPrimary: '#53389E',
    lightSecondary: '#F4EBFF',
    darkSecondary: '#339790',

    bgToastSuccess: '#E1F0DA',

    danger: '#E01B00',
    darkDanger: '#BE1700',

    warning: '#3F95E7',
    link: '#175CD3',
    success: '#009D4F',
    success100: '#008C47',
    error: '#E9645B',

    borderBottom: '#FAFAFA',

    backgroundFB: '#1877F2',

    appBarColor: '#53389E',

    white: '#FFFFFF',
    grey50: '#F9FAFB',
    grey100: '#FAFAFA',
    grey200: '#EEEEEE',
    grey300: '#DDDDDD',
    grey400: '#C5C5C5',
    grey600: '#85858A',

    gold100: '#FFFBE6',
    gold200: '#FFF1B8',
    gold300: '#FFE58F',
    gold600: '#FAAD14',
    gold700: '#D48806',

    outlinedButton: process.env.REACT_APP_COLOR_OUTLINED_BUTTON!,
    containedButtonBg: '#B692F6',
    containedButtonString: process.env.REACT_APP_COLOR_CONTAINED_BUTTON_TEXT!,
    pink: '#E81F76',
};

export default color;
