export const appConfig = {
    gateway: {
        backendURL: process.env.REACT_APP_BACKEND_URL!,
        host: process.env.HOST!,
        blobURL: process.env.REACT_APP_NAILS_BLOB_URL!,
        urlReview: 'https://nailshopuk.co.uk/review',
        linkRedirect: 'https://brandname.ltd/register?code=',
    },
    axios: {
        urlApiFacebook: process.env.REACT_APP_API_FACEBOOK_URL!,
        getFields: process.env.REACT_APP_API_FIELDS_FACEBOOK!,
    },
};
