import React from 'react';
import color from 'theme/Colors';

type Props = {
    color: string;
    size: number;
};
export default function FbIcon(props: Props) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.3459 12.0699C23.3459 5.7186 18.1972 0.56988 11.8459 0.56988C5.49467 0.56988 0.345947 5.7186 0.345947 12.0699C0.345947 17.8099 4.55133 22.5674 10.0491 23.4302V15.3941H7.12915V12.0699H10.0491V9.53629C10.0491 6.6541 11.7659 5.06207 14.3928 5.06207C15.651 5.06207 16.967 5.28668 16.967 5.28668V8.11675H15.5169C14.0883 8.11675 13.6428 9.00322 13.6428 9.91266V12.0699H16.8323L16.3224 15.3941H13.6428V23.4302C19.1406 22.5674 23.3459 17.8099 23.3459 12.0699Z"
                fill={props.color}
            />
        </svg>
    );
}
