import { BaseHttpController } from '@Core/controller/BaseHttpController';

import { IAccountHttpController } from '../IAccountHttpController';
import { AxiosInstance } from 'axios';
import { Account, RegisterProps } from '../../models/Account';
import { Shop } from '../../models/Shop';

export class RedirectLinkController extends BaseHttpController<any> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'review', client);
    }
    async redirectToLinks(code: string): Promise<Shop> {
        return await this.doGet({ path: `${code}` }).then((res) => res.data);
    }
}
