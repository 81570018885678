import { BaseHttpController } from '@Core/controller/BaseHttpController';

import { IAccountHttpController } from '../IAccountHttpController';
import { AxiosInstance } from 'axios';
import { Account, RegisterProps } from '../../models/Account';
import { Shop } from '../../models/Shop';
import { FbAuth } from '../../models/FacebookConnection';

export class FbConnectionHttpController extends BaseHttpController<any> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'fb-auth', client);
    }
    async connectFanpageFb(props: FbAuth): Promise<string> {
        return await this.doPost({ path: 'connect', body: props }).then((res) => res.data);
    }
}
