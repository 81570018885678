import { BaseModel, Paging } from '@Core';
import {
    Box,
    Grow,
    Pagination as MuiPagination,
    Stack,
    SxProps,
    Theme
} from '@mui/material';
import { ReactNode } from 'react';
import CircularLoading from './CircularLoading';
import ListNoData from './NoDataPage';

type Props<T extends BaseModel> = {
    paging: Paging<T>;
    styleContainer?: SxProps<Theme>;
    styleList?: SxProps<Theme>;
    isLoading?: boolean;
    setPage: (page: number) => void;
    renderedItem: (item: T, index: number) => JSX.Element;
    noDataComponent?: ReactNode;
};

export default function BaseListRender<T extends BaseModel>(props: Props<T>) {
    const { paging, setPage, isLoading } = props;
    return (
        <Stack sx={{ ...props.styleContainer }}>
            {isLoading ? (
                <CircularLoading isHeight />
            ) : paging.rows.length ? (
                <Stack direction="column" sx={{ ...props.styleList }}>
                    {paging.rows.map((item, index) => {
                        return (
                            <Grow in={true} key={index} timeout={Math.min(500 * index, 1000)}>
                                <Box height={'100%'}>{props.renderedItem(item, index)}</Box>
                            </Grow>
                        );
                    })}
                </Stack>
            ) : (
                props.noDataComponent ?? <ListNoData />
            )}

            {paging.totalPages > 1 && !isLoading && (
                <Stack mt={3} direction={'row'} justifyContent="center">
                    <MuiPagination
                        count={paging.totalPages || 1}
                        page={paging.page}
                        onChange={(_, page) => setPage(page)}
                        variant="outlined"
                        shape="rounded"
                    />
                </Stack>
            )}
        </Stack>
    );
}
