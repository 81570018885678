import { Stack, Typography } from '@mui/material';
import CircularLoading from 'components/CircularLoading';
import ListNoData from 'components/NoDataPage';
import PopUpWarning from 'components/PopUpWarning';
import useDebounce from 'components/hooks/useDebounce';
import { useAuth } from 'hook/useAuth';
import { useMemo } from 'react';
import color from '../../theme/Colors';
import ShopItemInfo from './ShopItemInfo';
import PopUpChangeNameShop from './components/PopUpChangeNameShop';
import PopupEditOrAddLink from './components/PopupEditOrAddLink';
import useShopInfoProvider, { ShopInfoContext } from './components/ShopInfoProvider';

export default function ShopInfoPage() {
    const shopInfo = useShopInfoProvider();

    const {
        isMediumMD,
        isLoading,
        setFilterSearch,
        popUpConfirmRemoveFormEdit,
        popUpEditOrAdd,
        popUpChangeNameShop,
        handleRemoveForm,
        idForm,
        setIdForm,

        shopDetail,
        filterSearch,
    } = shopInfo;

    const authData = useAuth();
    const { isCheckUIEmployee, isCheckUICustomer } = authData;

    const debouncedValue = useDebounce<string>(filterSearch, 500);

    const filteredSearchName = useMemo(() => {
        return shopDetail?.filter((item) => {
            return item.Name?.toLowerCase().includes(debouncedValue.toLowerCase());
        });
    }, [debouncedValue, shopDetail]);

    return (
        <>
            {isCheckUICustomer && (
                <ShopInfoContext.Provider value={shopInfo}>
                    <Stack direction="column" justifyContent={'space-between'} mb={3}>
                        <Stack my={isMediumMD ? 2 : 4} spacing={isMediumMD ? 1 : 2} direction={'column'}>
                            <Typography variant="h2">My shop</Typography>
                        </Stack>
                        {/* 
                <Stack>
                    <BaseTextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            borderRadius: '32px !important',
                            '& .MuiInputBase-input': {
                                padding: isMediumMD ? '8px' : '12px',
                            },
                        }}
                        onChange={(e) => setFilterSearch(e.target.value)}
                        placeholder="Search name"
                    />
                </Stack> */}

                        <Stack mb={3}>
                            <Typography
                                textAlign={'right'}
                                variant={isMediumMD ? 'body2' : 'h6'}
                                sx={{ color: color.success }}
                            >
                                Total shop: {shopDetail.length ?? 0}
                            </Typography>
                        </Stack>

                        {isLoading ? (
                            <CircularLoading isHeight />
                        ) : (
                            <Stack spacing={2}>
                                {shopDetail.length > 0 ? (
                                    <>
                                        {shopDetail?.map((item, index) => {
                                            return <ShopItemInfo key={item.Id} shopItems={item} index={index} />;
                                        })}
                                    </>
                                ) : (
                                    <ListNoData />
                                )}
                            </Stack>
                        )}
                        <PopUpWarning
                            {...popUpConfirmRemoveFormEdit}
                            title="Delete"
                            message="Are you sure you want to delete this link?"
                            onConfirm={() => {
                                handleRemoveForm(idForm);
                                popUpConfirmRemoveFormEdit.onClose();
                            }}
                            onClose={() => {
                                setIdForm(0);
                                popUpConfirmRemoveFormEdit.onClose();
                            }}
                            responsiveWidthButton={isMediumMD ? 120 : 150}
                        />
                        <PopupEditOrAddLink {...popUpEditOrAdd} />
                        <PopUpChangeNameShop {...popUpChangeNameShop} />
                    </Stack>
                </ShopInfoContext.Provider>
            )}
        </>
    );
}
