import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function LogoutIcon(props: Props) {
    return (
        <svg
            {...props}
            width="20"
            height="20"
            viewBox="0 0 32 32"
            fill="#E01B00"
            className="svg-icon-fill"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 5.5C4 3.567 5.567 2 7.5 2H12.5C13.3284 2 14 2.67158 14 3.5C14 4.32842 13.3284 5 12.5 5H7.5C7.22386 5 7 5.22386 7 5.5V26.5C7 26.7762 7.22386 27 7.5 27H12.5C13.3284 27 14 27.6716 14 28.5C14 29.3284 13.3284 30 12.5 30H7.5C5.567 30 4 28.433 4 26.5V5.5ZM24.8786 14.5H13.5C12.6716 14.5 12 15.1716 12 16C12 16.8284 12.6716 17.5 13.5 17.5H24.8786L20.9394 21.4394C20.3536 22.0252 20.3536 22.9748 20.9394 23.5606C21.5252 24.1464 22.4748 24.1464 23.0606 23.5606L29.5606 17.0607C30.1464 16.4749 30.1464 15.5251 29.5606 14.9393L23.0606 8.43934C22.4748 7.85356 21.5252 7.85356 20.9394 8.43934C20.3536 9.02512 20.3536 9.97488 20.9394 10.5607L24.8786 14.5Z"
            />
        </svg>
    );
}
