import MenuIcon from '@mui/icons-material/Menu';
import { Drawer } from '@mui/material';
import Box from '@mui/material/Box';

import { useAuth } from 'hook/useAuth';
import { useNavigate } from 'react-router-dom';
import useMedia from '../../hook/useMedia';
import ListItemMenuLeft from '../ListItemMenuLeft';
import PopUpWarning from '../PopUpWarning';
import { useDrawerContext } from './useDrawer';

export default function MyDrawer() {
    const drawerContext = useDrawerContext();
    const { popUpWarning, openPopup, toggleDrawer } = drawerContext;

    const { isMediumMD } = useMedia();

    const authContext = useAuth();
    const { logout, isCheckUIEmployee } = authContext;
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                '&.MuiBox-root': {
                    lineHeight: '1px',
                },
            }}
        >
            {isMediumMD ? (
                <>
                    <Box onClick={toggleDrawer(true)}>
                        <MenuIcon style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                    </Box>
                    <Drawer open={openPopup} onClose={toggleDrawer(false)}>
                        <ListItemMenuLeft popUpWarning={popUpWarning} {...popUpWarning} openPopup={openPopup} />
                    </Drawer>
                </>
            ) : (
                <></>
            )}

            <PopUpWarning
                {...popUpWarning}
                onConfirm={() => {
                    logout();
                    navigate('/sign-in');
                }}
                title="Logout"
                message="Are you sure you want to log out?"
                responsiveWidthButton={120}
            />
        </Box>
    );
}
