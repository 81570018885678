import {
    Avatar,
    Box,
    Button,
    Fade,
    Grow,
    IconButton,
    ImageList,
    ImageListItem,
    Link,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import AddLink from 'assets/jsx-icon/AddLink';
import ListNoData from 'components/NoDataPage';
import { getIconFromURL } from 'pages/components';
import { useEffect } from 'react';
import { LinkReview } from 'submodules/nail-supplier-base-model/models/LinkReview';
import { Shop } from 'submodules/nail-supplier-base-model/models/Shop';
import EditIcon from '../../assets/jsx-icon/EditIcon';
import ICDelete from '../../assets/jsx-icon/ICDelete';
import RiceStalksLeft from '../../assets/jsx-icon/RiceStalksLeft';
import RiceStalksRight from '../../assets/jsx-icon/RiceStalksRight';
import useMedia from '../../hook/useMedia';
import color from '../../theme/Colors';
import { useShop } from './components/ShopInfoProvider';
import { formatUKDate, getAvatarFromJira } from 'helper';
import Image from 'mui-image';

export interface IconFromURL extends Partial<LinkReview> {
    isEdit: boolean;
}

interface Props {
    shopItems: Partial<Shop>;
    index: number;
}

export default function ShopItemInfo(props: Props) {
    const { isMediumMD } = useMedia();

    const { shopItems, index } = props;

    const shopInfo = useShop();

    const {
        setIdForm,
        setIdShop,
        formLinkReviews,
        setFormLinkReviews,
        popUpEditOrAdd,
        popUpConfirmRemoveFormEdit,
        popUpChangeNameShop,
        setFilterSearch,
    } = shopInfo;

    const filteredListLinkReviews = formLinkReviews.filter((item) => item.ShopId === shopItems.Id);

    useEffect(() => {
        if (shopItems && shopItems.LinkReviews) {
            setFormLinkReviews((prevFormLinkReviews) => [
                ...prevFormLinkReviews,
                ...shopItems.LinkReviews!.map((linkReview) => ({
                    ...linkReview,
                })),
            ]);
        }
    }, []);

    return (
        <Stack key={shopItems.Id} padding={'16px'} textAlign={'start'} bgcolor={color.white} borderRadius={2}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'start'}>
                    <Box width={42} height={42} sx={{ marginRight: isMediumMD ? '12px' : '16px' }}>
                        <Image
                            style={{
                                borderRadius: '50%',
                            }}
                            duration={500}
                            src={getAvatarFromJira(index)}
                            alt={`Avatar ${index}`}
                        />
                    </Box>
                    <Stack direction={'column'}>
                        <Stack direction={'row'} spacing={0.5}>
                            <Typography
                                variant="h5"
                                sx={{
                                    wordBreak: 'break-all',
                                }}
                            >
                                {shopItems?.Name ?? `Shop name ${index + 1}`}
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    setFilterSearch(shopItems.Name ?? `Shop name ${index + 1}`);
                                    setIdShop(shopItems.Id ?? 0);
                                    popUpChangeNameShop.setTrue();
                                }}
                                sx={{ p: 0 }}
                                style={{
                                    marginTop: '-2px',
                                }}
                            >
                                <EditIcon fontSize={16} />
                            </IconButton>
                        </Stack>
                        <Typography textAlign={'left'} variant="body2" color={color.secondary}>
                            Shop creation date: {formatUKDate(shopItems?.Created!)}
                        </Typography>
                        {/* <Link
                            href={
                                filteredListLinkReviews.length > 0 ? `/review/${shopItems.NFCCode}` : `/page-not-found`
                            }
                            color={color.link}
                            underline="always"
                            target="_blank"
                            fontSize={'12px'}
                            width={'max-content'}
                        >
                            Click to link review
                        </Link> */}
                    </Stack>
                </Stack>
            </Stack>
            <Stack my={3}>
                <Stack alignItems={'end'} mb={1} justifyContent={'center'} direction={'row'} spacing={1}>
                    <RiceStalksLeft />
                    <Typography textAlign={'center'} variant="h2" lineHeight={1} color={color.success}>
                        {shopItems?.AccessCount ?? 0}
                    </Typography>
                    <RiceStalksRight />
                </Stack>

                <Typography textAlign={'center'} variant="h5" color={color.grey600}>
                    Access times
                </Typography>
            </Stack>
            <Stack padding={'16px'} bgcolor={color.grey50} borderRadius={2} direction={'column'} spacing={1}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    borderBottom={`1px solid ${color.grey200}`}
                    paddingBottom={1}
                >
                    <Typography variant="h5">Visit: </Typography>
                    <Button
                        onClick={() => {
                            setIdShop(shopItems.Id ?? 0);
                            popUpEditOrAdd.setTrue();
                        }}
                        startIcon={<AddLink color={color.priText} />}
                    >
                        Add link
                    </Button>
                </Stack>

                {filteredListLinkReviews.length ? (
                    <>
                        {filteredListLinkReviews?.map((items, index) => {
                            const iconURL = getIconFromURL(items.Url ?? '', isMediumMD ? 16 : 24);
                            return (
                                <Grow in={true} key={items.ShopId} timeout={Math.min(500 * items.ShopId, 1000)}>
                                    <Box height={'100%'}>
                                        <Stack padding={'8px 0px'} direction={'row'} justifyContent={'space-between'}>
                                            <Stack
                                                direction={'row'}
                                                width={'100%'}
                                                alignItems={'center'}
                                                spacing={isMediumMD ? 2 : 3}
                                                sx={{
                                                    overflowWrap: 'break-word',
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                {iconURL && <img src={iconURL} alt="Website Icon" />}
                                                <Link
                                                    href={`${items.Url}`}
                                                    color={color.link}
                                                    underline="always"
                                                    target="_blank"
                                                >
                                                    {items.Title || items.Url}
                                                </Link>
                                            </Stack>
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                                justifyContent={'space-between'}
                                                style={{ marginLeft: 'auto' }}
                                            >
                                                <Tooltip title="Edit link" arrow placement="top">
                                                    <IconButton
                                                        onClick={() => {
                                                            popUpEditOrAdd.setTrue();
                                                            setIdShop(shopItems.Id ?? 0);
                                                            setIdForm(items.Id!);
                                                        }}
                                                    >
                                                        <EditIcon fontSize={16} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete link" arrow placement="top">
                                                    <IconButton
                                                        onClick={() => {
                                                            setIdForm(items.Id!);
                                                            popUpConfirmRemoveFormEdit.setTrue();
                                                        }}
                                                    >
                                                        <ICDelete widthAndHeight={11} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Grow>
                            );
                        })}
                    </>
                ) : (
                    <ListNoData title="Add link" />
                )}
            </Stack>
        </Stack>
    );
}
