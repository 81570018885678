import { BaseHttpController } from '@Core/controller/BaseHttpController';

import { IAccountHttpController } from '../IAccountHttpController';
import { AxiosInstance } from 'axios';
import { Account } from '../../models/Account';
import { Shop } from '../../models/Shop';
import { IShopHttpController, UpdateShopName } from '../IShopHttpController';

export class ShopHttpController extends BaseHttpController<Shop> implements IShopHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'shop', client);
    }
    async createShop(): Promise<Shop> {
        return await this.doPost({ path: 'create-shop', body: {} }).then((res) => res.data);
    }
    async createShopWithLink(url: string, redirect?: string): Promise<Shop> {
        return await this.doPost({ path: 'create-shop-with-link', body: { url, redirect } }).then((res) => res.data);
    }
    async updateShopName(props: UpdateShopName): Promise<Shop[]> {
        return await this.doPost({ path: 'update-shop', body: props }).then((res) => res.data);
    }
    async getList(): Promise<Shop[]> {
        return await this.doGet({ path: 'list' }).then((res) => res.data);
    }
}
