import ListArticlesIcon from 'assets/jsx-icon/ListArticlesIcon';
import PostArticlesIcon from 'assets/jsx-icon/PostArticlesIcon';
import ShopIcon from 'assets/jsx-icon/ShopIcon';
import React, { createContext, useContext, useState } from 'react';
import usePopUp from '../hooks/usePopUp';
import FacebookIcon from 'assets/jsx-icon/FacebookIcon';
export interface DrawerItem {
    id: DrawerPage;
    label: string;
    icon: JSX.Element;
    path?: any;
}

export enum DrawerPage {
    MyShop,
    PostContent,
    ListContent,
    FacebookManagement,
}

export default function useDrawer() {
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState<DrawerPage>(DrawerPage.MyShop);
    const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);

    const popUpWarning = usePopUp();

    const handleDrawerClose = () => {
        setOpen(!open);
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const changeIsShowDrawer = () => {
        setIsShowDrawer(!isShowDrawer);
    };

    const [openPopup, setOpenPopup] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenPopup(newOpen);
    };

    let ListDrawItem: DrawerItem[] = [
        {
            id: DrawerPage.MyShop,
            label: 'Shop',
            icon: <ShopIcon />,
            path: '/my-shop',
        },
        {
            id: DrawerPage.PostContent,
            label: 'Post content',
            icon: <ListArticlesIcon />,
            path: '/post-content',
        },
        {
            id: DrawerPage.ListContent,
            label: 'List content',
            icon: <PostArticlesIcon />,
            path: '/list-content',
        },

        {
            id: DrawerPage.FacebookManagement,
            label: 'Facebook',
            icon: <FacebookIcon />,
            path: '/facebook-management',
        },
    ];

    return {
        ListDrawItem,
        open,
        setOpen,
        handleDrawerClose,
        activeItem,
        setActiveItem,
        isShowDrawer,
        changeIsShowDrawer,
        setIsShowDrawer,
        handleDrawerOpen,
        popUpWarning,
        openPopup,
        toggleDrawer,
        setOpenPopup,
    };
}

type DrawerContextType = ReturnType<typeof useDrawer>;

export const DrawerContext = createContext<DrawerContextType>({} as DrawerContextType);

export const useDrawerContext = () => useContext(DrawerContext);
