import { Stack, Typography } from '@mui/material';
import React from 'react';
import ButtonFacebookConnect from './ButtonFacebookConnect';

export default function PageNotYetConnected() {
    return (
        <Stack width="100%" minHeight={400} alignItems="center" textAlign={'center'} justifyContent="center">
            <Typography variant="h2" fontWeight={400} color="primary.dark">
                Sorry, you currently have no accounts connected to Facebook
            </Typography>
            <Typography variant="h6" fontWeight={400} align="center">
                Click on the button below to connect
            </Typography>
            <Stack mt={2} width="100%" alignItems={'center'}>
                <ButtonFacebookConnect />
            </Stack>
        </Stack>
    );
}
