import { Box, Container, Grid } from '@mui/material';

import CircularLoading from 'components/CircularLoading';
import { AuthContext, useAuthContext } from 'hook/useAuth';
import Login from 'pages/loginPage';
import { Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ListItemMenuLeft from '../components/ListItemMenuLeft';
import PopUpWarning from '../components/PopUpWarning';
import useDrawer, { DrawerContext } from '../components/drawer/useDrawer';
import MyAppBar from '../components/header/MyAppBar';
import useMedia from '../hook/useMedia';
import color from '../theme/Colors';

function AuthLayout() {
    const drawerContext = useDrawer();
    const navigate = useNavigate();

    const { isMediumMD } = useMedia();

    const authData = useAuthContext();
    const { logout, isLoading } = authData;

    return (
        <Box sx={{ overflowX: 'hidden', pt: 0 }}>
            <AuthContext.Provider value={authData}>
                {
                    <>
                        {isLoading ? (
                            <CircularLoading isHeight={false} />
                        ) : !authData.isAuth ? (
                            <Login />
                        ) : (
                            <DrawerContext.Provider value={drawerContext}>
                                <MyAppBar />

                                <Grid container direction={'row'}>
                                    <Grid py={1} item xs={2} bgcolor={color.white} position={'fixed'} zIndex={2}>
                                        {isMediumMD ? (
                                            <></>
                                        ) : (
                                            <>
                                                <ListItemMenuLeft
                                                    {...drawerContext.popUpWarning}
                                                    popUpWarning={drawerContext.popUpWarning}
                                                    openPopup={true}
                                                />
                                                <PopUpWarning
                                                    {...drawerContext.popUpWarning}
                                                    onConfirm={() => {
                                                        logout();
                                                        navigate('/sign-in');
                                                    }}
                                                    title="Logout"
                                                    message="Are you sure you want to log out?"
                                                    responsiveWidthButton={150}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={12} md={9} mt={8}>
                                        <Container>
                                            <Suspense>
                                                <Outlet />
                                            </Suspense>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </DrawerContext.Provider>
                        )}
                    </>
                }
            </AuthContext.Provider>
        </Box>
    );
}

export default AuthLayout;
