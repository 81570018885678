import { Button, Stack, Typography } from '@mui/material';
import { IPopUp } from 'components/hooks/usePopUp';
import PopUpBase from 'components/PopUpBase';
import React from 'react';

type Props = Omit<IPopUp, 'onConfirm'> & {
    handleCreateShopLink: () => Promise<void>;
};
export default function PopUpConfirmCreateNewShop(props: Props) {
    const { open, onClose, handleCreateShopLink } = props;
    return (
        <PopUpBase
            title={<Typography variant="h4">Confirm</Typography>}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'xs',
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                },
            }}
            open={open}
            onClose={onClose}
            onConfirm={() => {}}
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Typography variant="body1" textAlign={'center'}>
                    Are you sure you want to create a new shop?
                </Typography>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant={'contained'}
                        onClick={async () => {
                            await handleCreateShopLink();
                        }}
                    >
                        Confirm
                    </Button>
                </Stack>
            }
        />
    );
}
