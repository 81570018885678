import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;
export default function EditIcon(props: Props) {
    return (
        <svg
            width={props.fontSize ? props.fontSize : 20}
            height={props.fontSize ? props.fontSize : 20}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.84006 3.33549L3.36673 9.12882C3.16006 9.34882 2.96006 9.78215 2.92006 10.0822L2.6734 12.2422C2.58673 13.0222 3.14673 13.5555 3.92006 13.4222L6.06673 13.0555C6.36673 13.0022 6.78673 12.7822 6.9934 12.5555L12.4667 6.76215C13.4134 5.76215 13.8401 4.62215 12.3667 3.22882C10.9001 1.84882 9.78673 2.33549 8.84006 3.33549Z"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.92676 4.30225C8.21342 6.14225 9.70676 7.54891 11.5601 7.73558"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 15.6022H14"
                stroke="#85858A"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
