import { ContentPaste } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import PasteIcon from 'assets/jsx-icon/PasteIcon';
import { BaseTextField } from 'components/BaseTextField';
import { FormValue } from 'components/header/MyAppBar';
import usePopUp, { IPopUp } from 'components/hooks/usePopUp';
import PopUpBase from 'components/PopUpBase';
import RequiredTitle from 'components/RequiredTitle';
import { pushError, pushSuccess } from 'components/toast';
import { appConfig } from 'configs';
import { shopController } from 'controllers';
import useMedia from 'hook/useMedia';
import { useEffect, useState } from 'react';
import {
    Control,
    Controller,
    FieldErrors,
    useForm,
    UseFormClearErrors,
    UseFormReset,
    UseFormWatch,
} from 'react-hook-form';
import color from 'theme/Colors';

type Props = Omit<IPopUp, 'onConfirm'> & {
    reset: UseFormReset<FormValue>;
    clearErrors: UseFormClearErrors<FormValue>;
    watch: UseFormWatch<FormValue>;
    control: Control<FormValue, any>;
    errors: FieldErrors<FormValue>;
    popUpConfirmCreateNewLinkShop: {
        setTrue: () => void;
        open: boolean;
        onClose: () => void;
        onConfirm: (() => void) | undefined;
    };
    handlePasteValue: () => Promise<void>;
};

export default function PopUpCreateNewLink(props: Props) {
    const {
        open,
        onClose,
        reset,
        clearErrors,
        watch,
        control,
        errors,
        popUpConfirmCreateNewLinkShop,
        handlePasteValue,
    } = props;

    useEffect(() => {
        if (!open) return;
    }, [open]);

    const { isMediumMD } = useMedia();

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        minWidth: '200px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">Create shop with link</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={isMediumMD ? 120 : 150}
            desc={
                <Stack
                    sx={{
                        backgroundColor: color.white,
                        borderRadius: '8px',
                        padding: '4px 2px',
                    }}
                    spacing={3}
                    direction={'column'}
                >
                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="New link" required lineHeight={0} />
                        <Controller
                            control={control}
                            name="createNewShop"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Link is required',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <BaseTextField
                                        {...field}
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={!!errors.createNewShop?.message}
                                        placeholder="Enter your link"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handlePasteValue}
                                                        sx={{
                                                            p: '4px',
                                                        }}
                                                    >
                                                        <PasteIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px ',
                                            },
                                        }}
                                    />
                                    <Typography ml={2} variant="body2" color={color.darkDanger}>
                                        {errors.createNewShop?.message}
                                    </Typography>
                                </>
                            )}
                        />
                    </Stack>
                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="Link redirect" lineHeight={0} />
                        <Controller
                            control={control}
                            name="createLinkRedirect"
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <BaseTextField
                                        {...field}
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={!!errors.createLinkRedirect?.message}
                                        placeholder="Enter your link redirect"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px ',
                                            },
                                            marginTop: '8px',
                                        }}
                                    />
                                    <Typography ml={2} variant="body2" color={color.darkDanger}>
                                        {errors.createLinkRedirect?.message}
                                    </Typography>
                                </>
                            )}
                        />
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            reset({
                                createNewShop: '',
                                createLinkRedirect: `${appConfig.gateway.linkRedirect}`,
                            });
                            clearErrors();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant={'contained'}
                        disabled={!watch().createNewShop}
                        onClick={() => {
                            popUpConfirmCreateNewLinkShop.setTrue();
                            props.onClose?.();
                        }}
                    >
                        Confirm
                    </Button>
                </Stack>
            }
        />
    );
}
