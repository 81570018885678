import { BaseHttpController } from '@Core/controller/BaseHttpController';

import { IAccountHttpController } from '../IAccountHttpController';
import { AxiosInstance } from 'axios';
import { Account, ChangePassword, RegisterProps } from '../../models/Account';

export class AccountHttpController extends BaseHttpController<Account> implements IAccountHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'account', client);
    }
    async changePassword(props: ChangePassword): Promise<string> {
        return await this.doPost({ path: 'change-password', body: props }).then((res) => res.data);
    }

    async getMe(): Promise<Account> {
        return await this.doGet({ path: 'get-me' }).then((res) => res.data);
    }
    async login(props: Account): Promise<string> {
        return await this.doPost({ path: 'login', body: props }).then((res) => res.data);
    }
    async registerOrConnectAccount(props: RegisterProps): Promise<string> {
        return await this.doPost({ path: 'register-or-connect', body: props }).then((res) => res.data);
    }
}
