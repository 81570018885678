import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function NoDataIcon(props: Props) {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_53_17122)">
                <path
                    d="M32 51.7032C49.6731 51.7032 64 48.5925 64 44.7552C64 40.9179 49.6731 37.8071 32 37.8071C14.3269 37.8071 0 40.9179 0 44.7552C0 48.5925 14.3269 51.7032 32 51.7032Z"
                    fill="#EEEEEE"
                />
                <path
                    d="M55 25.6653L44.854 14.2487C44.367 13.4705 43.656 13 42.907 13H21.093C20.344 13 19.633 13.4705 19.146 14.2477L9 25.6663V34.8367H55V25.6653Z"
                    stroke="#C5C5C5"
                    strokeWidth="2"
                />
                <path
                    d="M41.613 28.8128C41.613 27.2197 42.607 25.9046 43.84 25.9036H55V43.9059C55 46.0132 53.68 47.7402 52.05 47.7402H11.95C10.32 47.7402 9 46.0122 9 43.9059V25.9036H20.16C21.393 25.9036 22.387 27.2167 22.387 28.8098V28.8317C22.387 30.4248 23.392 31.7111 24.624 31.7111H39.376C40.608 31.7111 41.613 30.4128 41.613 28.8198V28.8128V28.8128Z"
                    fill="#FAFAFA"
                    stroke="#C5C5C5"
                    strokeWidth="2"
                />
            </g>
            <defs>
                <clipPath id="clip0_53_17122">
                    <rect width="64" height="40" fill="white" transform="translate(0 12)" />
                </clipPath>
            </defs>
        </svg>
    );
}
