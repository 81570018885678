import { CssBaseline, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import MultiProvider from './components/MultiProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './theme/Theme';
import BackdropProvider from 'providers/BackdropProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <MultiProvider providers={[<ThemeProvider theme={theme} />, <BackdropProvider />]}>
        <CssBaseline />
        <App />
        <ToastContainer
            autoClose={3000}
            hideProgressBar
            closeButton={false}
            position={'bottom-right'}
            newestOnTop
            pauseOnFocusLoss={false}
            closeOnClick
        />
    </MultiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
