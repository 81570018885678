import { Stack, Typography } from '@mui/material';
import FbIcon from 'assets/jsx-icon/FbIcon';
import { useAuth } from 'hook/useAuth';
import PopUpErrorConnectFacebook from 'pages/myShopPage/components/PopUpErrorConnectFacebook';
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import color from 'theme/Colors';

type Props = {
    isCheckUI?: boolean;
};

export default function ButtonFacebookConnect(props: Props) {
    const authData = useAuth();
    const { responseFacebook, errorConnectFB, iserrorConnectFB, popUpConnectFBError, tokenFB } = authData;

    const onFailure = (error?: any) => {
        console.log(error);
    };
    return (
        <>
            <FacebookLogin
                version="19.0"
                appId="374896968835927"
                fields="name,email,picture"
                autoLoad={false}
                callback={responseFacebook}
                onFailure={(e) => onFailure(e)}
                scope="pages_show_list,pages_manage_posts,pages_read_engagement"
                render={(renderProps) => (
                    <Stack
                        onClick={renderProps.onClick}
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                        padding={'8px 16px'}
                        minWidth={'40%'}
                        sx={{
                            backgroundColor: color.backgroundFB,
                            cursor: 'pointer',
                            borderRadius: 2,
                            padding: props.isCheckUI ? '6px 12px' : '12px 16px',
                            transition: 'linear 0.2s',
                            '&:hover': {
                                backgroundColor: '#2D46B9',
                            },
                        }}
                    >
                        <Stack>
                            <FbIcon color={color.white} size={props.isCheckUI ? 16 : 20} />
                        </Stack>
                        <Typography color={color.white} fontWeight={600} variant={props.isCheckUI ? 'body2' : 'h5'}>
                            {tokenFB ? 'Reconnect' : 'Connect facebook'}
                        </Typography>
                    </Stack>
                )}
                redirectUri="https://f150-14-241-150-19.ngrok-free.app/"
            />
            <PopUpErrorConnectFacebook
                {...popUpConnectFBError}
                errorConnectFB={errorConnectFB}
                iserrorConnectFB={iserrorConnectFB}
            />
        </>
    );
}
