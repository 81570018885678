import { Stack, Typography } from '@mui/material';
import useMedia from 'hook/useMedia';
import { useNavigate } from 'react-router-dom';
import color from 'theme/Colors';
import BackToNailSupplier from './components/BackToNailSupplier';

export default function PageNotFound() {
    const { isMediumMD } = useMedia();
    return (
        <Stack
            alignItems={'center'}
            justifyContent={'center'}
            minHeight={'calc(100vh - 100px)'}
            spacing={2}
            position={'relative'}
        >
            <img
                src="assets/images/404PageNotFoundError.png"
                alt=""
                width={isMediumMD ? '90%' : '40%'}
                height={'auto'}
            />
            <Typography variant="h1">Oops!</Typography>
            <Typography variant="h6" color={color.grey600}>
                Sorry, we can't find that page you're looking for.
            </Typography>
            {/* <Button
                onClick={() => navigate('/my-shop')}
                sx={{
                    padding: '8px 16px',
                    borderRadius: 1,
                    minWidth: '200px',
                    fontSize: '14px',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    color: color.white,
                    backgroundColor: color.textPrimary,
                    '&:hover': {
                        backgroundColor: color.textSecondary,
                    },
                }}
            >
                Go back
            </Button> */}
            <BackToNailSupplier />
        </Stack>
    );
}
