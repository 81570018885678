import React from 'react';
import color from 'theme/Colors';

export default function ShopIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            className="icon-svg-fill"
            fill={color.grey600}
            viewBox="0 0 35 35"
            id="store"
        >
            <path d="M28.31,33.66H6.69a3.3,3.3,0,0,1-3.38-3.2V17.83a1.25,1.25,0,0,1,2.5,0V30.46a.81.81,0,0,0,.88.7H28.31a.81.81,0,0,0,.88-.7V17.83a1.25,1.25,0,0,1,2.5,0V30.46A3.3,3.3,0,0,1,28.31,33.66Z"></path>
            <path d="M20.79,19.3a4.27,4.27,0,0,1-2.29-.67l-1-.67a1.71,1.71,0,0,0-1.81,0l-1.1.66a4.23,4.23,0,0,1-4.78-.3,1.72,1.72,0,0,0-2-.11l-.42.26A4.19,4.19,0,0,1,2.33,18l-.74-.68A4.23,4.23,0,0,1,.25,14.21v-2.5A6.22,6.22,0,0,1,1,8.79L3.38,4.28A5.44,5.44,0,0,1,8.12,1.34H26.88a5.44,5.44,0,0,1,4.74,2.94L34,8.79a6.22,6.22,0,0,1,.72,2.92V14a4.22,4.22,0,0,1-1.68,3.36l-1.26.94a4.19,4.19,0,0,1-5.19-.11L26.34,18a1.72,1.72,0,0,0-2-.14l-1.4.85A4.15,4.15,0,0,1,20.79,19.3Zm-4.24-4.11a4.2,4.2,0,0,1,2.29.68l1,.66a1.71,1.71,0,0,0,1.81,0l1.41-.86a4.22,4.22,0,0,1,4.85.35l.28.23a1.68,1.68,0,0,0,2.1,0l1.26-.94h0A1.7,1.7,0,0,0,32.25,14V11.71A3.67,3.67,0,0,0,31.82,10L29.41,5.46a2.91,2.91,0,0,0-2.53-1.62H8.12A2.91,2.91,0,0,0,5.59,5.46L3.18,10a3.67,3.67,0,0,0-.43,1.74v2.5a1.75,1.75,0,0,0,.54,1.25l.74.68a1.7,1.7,0,0,0,2.06.19l.42-.26a4.2,4.2,0,0,1,4.83.27,1.72,1.72,0,0,0,1.94.12l1.09-.66A4.15,4.15,0,0,1,16.55,15.19Z"></path>
            <path d="M32.87 12.12H2.13a1.25 1.25 0 0 1 0-2.5H32.87a1.25 1.25 0 0 1 0 2.5zM21.5 33.08a1.25 1.25 0 0 1-1.25-1.25V25.75a1.1 1.1 0 0 0-1.1-1.1h-3.3a1.1 1.1 0 0 0-1.1 1.1v6.08a1.25 1.25 0 0 1-2.5 0V25.75a3.61 3.61 0 0 1 3.6-3.6h3.3a3.61 3.61 0 0 1 3.6 3.6v6.08A1.25 1.25 0 0 1 21.5 33.08z"></path>
        </svg>
    );
}
