import { LoadingButton } from '@mui/lab';
import { Button, Grid, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import ConfirmIcon from 'assets/jsx-icon/ConfirmIcon';
import CopyIcon from 'assets/jsx-icon/CopyIcon';
import { BaseTextField } from 'components/BaseTextField';
import usePopUp, { IPopUp } from 'components/hooks/usePopUp';
import PopUpBase from 'components/PopUpBase';
import RequiredTitle from 'components/RequiredTitle';
import { pushError } from 'components/toast';
import { appConfig } from 'configs';
import { shopController } from 'controllers';
import useMedia from 'hook/useMedia';
import React, { useEffect, useState } from 'react';
import { Shop } from 'submodules/nail-supplier-base-model/models/Shop';
import color from 'theme/Colors';

type Props = Omit<IPopUp, 'onConfirm'> & {
    shopDetail: Shop | undefined;
};

export default function PopUpCreateNewShop(props: Props) {
    const { open, onClose, shopDetail } = props;
    const apiUrl = appConfig.gateway.urlReview;
    const urlReview = `${apiUrl}/${shopDetail?.NFCCode}`;

    const { isMediumMD } = useMedia();
    const [copied, setCopied] = useState<boolean>(false);

    const handleClickCopiedCode = async () => {
        setCopied(true);
        await navigator.clipboard?.writeText(urlReview);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    useEffect(() => {
        if (!open) return;
    }, [open]);

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        minWidth: '200px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">New shop</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={isMediumMD ? 120 : 150}
            desc={
                <Stack
                    sx={{
                        backgroundColor: color.white,
                        borderRadius: '8px',
                        padding: '4px 2px',
                    }}
                    direction={'column'}
                >
                    <Stack
                        sx={{
                            backgroundColor: color.grey50,
                            borderRadius: '8px',
                            padding: '12px 12px',
                        }}
                        direction={'column'}
                        spacing={2}
                    >
                        <Grid container>
                            <Grid item xs={12} sm={2} mt={1}>
                                <Typography fontWeight={500}>Code:</Typography>
                            </Grid>
                            <Grid item xs={10} md={10} mt={1}>
                                <Typography>{shopDetail?.NFCCode}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={2} mt={1}>
                                <Typography fontWeight={500}>Link:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} mt={1}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                                    <Link
                                        href={`${urlReview}`}
                                        color={color.link}
                                        underline="always"
                                        target="_blank"
                                        sx={{
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {urlReview}
                                    </Link>
                                    <Tooltip title="Copy" arrow placement="top">
                                        <IconButton
                                            onClick={() => handleClickCopiedCode()}
                                            sx={{
                                                p: '4px',
                                            }}
                                        >
                                            {copied ? <ConfirmIcon color={color.success} /> : <CopyIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            {/* <Grid item xs={1} md={2} textAlign={'end'}>
                                <Tooltip title="Copy" arrow placement="top">
                                    <IconButton onClick={() => handleClickCopiedCode()}>
                                        {copied ? <ConfirmIcon color={color.success} /> : <CopyIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid> */}
                        </Grid>
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack alignItems={'end'} width="100%">
                    <Button
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Close
                    </Button>
                </Stack>
            }
        />
    );
}
