import { BaseModel } from '@Core';

export interface Role extends BaseModel {
    AccountId: number;
    Type: TypeRole;
}
export enum TypeRole {
    Admin,
    Employee,
    ContentCreator,
    Customer,
}
