import React from 'react';

export default function NextPage() {
    return (
        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.00399 4.09461L15.5303 10.6083C16.3011 11.3775 16.3023 12.6375 15.5331 13.4083L9.01941 19.9346"
                stroke="#292D32"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
