import { Button, InputAdornment, Stack, Typography } from '@mui/material';
import { BaseTextField } from 'components/BaseTextField';
import PopUpBase from 'components/PopUpBase';
import { IPopUp } from 'components/hooks/usePopUp';
import { pushError } from 'components/toast';
import { getIconFromURL } from 'pages/components';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useShop } from './ShopInfoProvider';
import RequiredTitle from 'components/RequiredTitle';

type Props = Omit<IPopUp, 'onConfirm'> & {};

type FormValues = {
    titleUrl: string;
    url: string;
};

export default function PopupEditOrAddLink(props: Props) {
    const { open, onClose } = props;

    const shopInfo = useShop();
    const {
        idForm,
        formLinkReviews,
        isMediumMD,
        handleEditOrAddlink,
        setIdForm,
        handleKeywordKeyPress,
        idShop,
        setIdShop,
    } = shopInfo;

    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        clearErrors,
        formState: { isDirty, isValid },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            url: '',
            titleUrl: '',
        },
    });
    const disabled = !(isValid && isDirty);

    const iconURL = getIconFromURL(watch().url || '', isMediumMD ? 16 : 24);

    useEffect(() => {
        if (!open) return;
        if (idForm !== 0) {
            const updatedLinkReviews = [...formLinkReviews];
            const filterLinkReview = updatedLinkReviews.filter((link) => link.Id === idForm);
            setValue('url', filterLinkReview[0].Url);
            setValue('titleUrl', filterLinkReview[0].Title || '');
        } else {
            reset({
                url: '',
                titleUrl: '',
            });
        }
    }, [open]);

    const handleClickPastValue = () => {
        navigator.clipboard
            .readText()
            .then((text) => {
                setValue('url', text);
            })
            .catch((err) => {
                pushError(err);
            });
    };

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        minWidth: '200px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">{idForm === 0 ? 'Add' : 'Edit'}</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={isMediumMD ? 120 : 150}
            desc={
                <Stack direction={'column'} spacing={2}>
                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="Title" required lineHeight={0} />
                        <Controller
                            name="titleUrl"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'This field is required.',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    placeholder={'Enter title link'}
                                    autoFocus={true}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                    value={field.value}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px ',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Stack>

                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="Link" required lineHeight={0} />
                        <Stack direction={'row'} alignItems={'center'}>
                            <Controller
                                name="url"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                    pattern: {
                                        value: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                        message: 'Please enter a valid URL.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Enter link'}
                                        autoFocus={false}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                        value={field.value}
                                        onKeyDown={(e) => {
                                            if (idForm !== 0) {
                                                handleKeywordKeyPress(e, {
                                                    ShopId: idShop ?? 0,
                                                    Id: idForm,
                                                    Url: watch().url,
                                                    Title: watch().titleUrl,
                                                });
                                            } else {
                                                handleKeywordKeyPress(e, {
                                                    ShopId: idShop ?? 0,
                                                    Url: watch().url,
                                                    Title: watch().titleUrl,
                                                });
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start" onClick={handleClickPastValue}>
                                                    {iconURL && <img src={iconURL} alt="Website Icon" />}
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px ',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            setIdForm(0);
                            setIdShop(0);
                            reset({});
                            clearErrors();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={disabled}
                        onClick={async () => {
                            if (idForm !== 0) {
                                await handleEditOrAddlink({
                                    ShopId: idShop ?? 0,
                                    Id: idForm,
                                    Url: watch().url,
                                    Title: watch().titleUrl,
                                });
                                reset({});
                            } else {
                                await handleEditOrAddlink({
                                    ShopId: idShop ?? 0,
                                    Url: watch().url,
                                    Title: watch().titleUrl,
                                });
                                reset({});
                            }
                            props.onClose?.();
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
