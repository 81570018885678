import { Fade, Stack, Typography } from '@mui/material';
import NextPage from 'assets/jsx-icon/NextPage';
import TextNails247 from 'assets/jsx-icon/TextNails247';
import CircularLoading from 'components/CircularLoading';
import { pushError } from 'components/toast';
import { loginIMG } from 'configs/ImageFromEnv';
import { redirectLinkController } from 'controllers';
import useMedia from 'hook/useMedia';
import { getIconFromURL } from 'pages/components';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Shop } from 'submodules/nail-supplier-base-model/models/Shop';
import color from 'theme/Colors';

export default function IntermediateLink() {
    const { code } = useParams();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const [shop, setShop] = useState<Shop>({} as Shop);

    const redirect = async () => {
        setLoading(true);
        if (!code) return;

        await redirectLinkController
            .redirectToLinks(code)
            .then((res) => {
                if (res.LinkReviews?.length == 1) {
                    const route = res.LinkReviews[0].Url;
                    if (route === '' || route.trim() === '') {
                        navigate('/page-not-found');
                    }
                    window.location.href = res.LinkReviews[0].Url;
                } else {
                    setShop(res);
                    setLoading(false);
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
                navigate('/page-not-found');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useLayoutEffect(() => {
        redirect();
    }, []);

    return (
        <Stack
            alignItems={'center'}
            justifyContent={'center'}
            direction={'column'}
            sx={{
                minHeight: 'calc(100vh - 110px)',
            }}
            spacing={3}
            mt={3}
        >
            {shop && (
                <>
                    {loading ? (
                        <CircularLoading isHeight />
                    ) : (
                        <Stack
                            direction={'column'}
                            width={'100%'}
                            alignItems={'center'}
                            spacing={2}
                            paddingBottom={3}
                            position={'relative'}
                        >
                            <Stack width={'100%'} alignItems={'center'} spacing={1}>
                                <img src={loginIMG} alt="" width={80} height={60} />
                                <TextNails247 />
                                <Typography variant="h2" color={color.textPrimary}>
                                    {shop.Name}
                                </Typography>
                            </Stack>

                            {shop.LinkReviews?.map((shopItem) => {
                                const iconURL = getIconFromURL(shopItem.Url || '', 24);
                                return (
                                    <Fade
                                        in={true}
                                        key={shopItem.ShopId}
                                        timeout={Math.min(1000 * shopItem.ShopId, 2000)}
                                    >
                                        <Stack
                                            direction={'row'}
                                            maxWidth={'800px'}
                                            width={'90%'}
                                            alignItems={'center'}
                                            my={3}
                                            borderRadius={'12px'}
                                            padding={1.5}
                                            justifyContent={'space-between'}
                                            sx={{
                                                cursor: 'pointer',
                                                overflowWrap: 'break-word',
                                                wordBreak: 'break-word',
                                                border: `1px solid transparent`,
                                                backgroundColor: color.white,
                                                '&:hover': {
                                                    background: color.lightPrimary,
                                                    border: `1px solid ${color.appBarColor}`,
                                                    transition: 'linear 0.2s',
                                                },
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => {
                                                window.open(shopItem.Url, '_blank');
                                            }}
                                        >
                                            <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                {iconURL && <img src={iconURL} alt="Website Icon" />}
                                                {/* <Stack direction={'column'}>
                                                    <Typography variant="h6" textAlign={'left'}>
                                                        {shopItem.Title}
                                                    </Typography>
                                                    <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                                                        <OclockIcon />
                                                        <Typography
                                                            textAlign={'left'}
                                                            variant="body2"
                                                            color={color.secondary}
                                                        >
                                                            {formatTime(shopItem.Created!)}
                                                        </Typography>
                                                    </Stack>
                                                </Stack> */}
                                                <Typography variant="h6" textAlign={'left'}>
                                                    {shopItem.Title}
                                                </Typography>
                                            </Stack>
                                            <Stack style={{ marginLeft: 'auto' }}>
                                                <NextPage />
                                            </Stack>
                                        </Stack>
                                    </Fade>
                                );
                            })}
                        </Stack>
                    )}
                </>
            )}
        </Stack>
    );
}
