import React from 'react';

export default function RemoveImgIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00016 14.6666C11.6668 14.6666 14.6668 11.6666 14.6668 7.99992C14.6668 4.33325 11.6668 1.33325 8.00016 1.33325C4.3335 1.33325 1.3335 4.33325 1.3335 7.99992C1.3335 11.6666 4.3335 14.6666 8.00016 14.6666Z"
                fill="white"
            />
            <path d="M6.11328 9.88661L9.88661 6.11328" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.88661 9.88661L6.11328 6.11328" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
