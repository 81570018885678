import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import PasswordLockIcon from 'assets/jsx-icon/PasswordLockIcon';
import { BaseTextField } from 'components/BaseTextField';
import { IPopUp } from 'components/hooks/usePopUp';
import PopUpBase from 'components/PopUpBase';
import RequiredTitle from 'components/RequiredTitle';
import { pushError, pushSuccess } from 'components/toast';
import { accountController } from 'controllers';
import { useAuth } from 'hook/useAuth';
import useMedia from 'hook/useMedia';
import { useBackdrop } from 'providers/BackdropProvider';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Account, ChangePassword } from 'submodules/nail-supplier-base-model/models/Account';
import color from 'theme/Colors';

type Props = Omit<IPopUp, 'onConfirm'> & {};
interface FormValue extends ChangePassword {
    confirmNewPassword: string;
}
export default function PopUpChangePassword(props: Props) {
    const { open, onClose } = props;
    const [passwordVisible, setPasswordVisible] = useState([]);
    const { isMediumMD } = useMedia();

    const handleClickShowPassword = (inputName: string) => {
        setPasswordVisible((prevState) => ({
            ...prevState,
            [inputName]: !prevState[inputName as any],
        }));
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const {
        control,
        handleSubmit,
        reset,
        clearErrors,
        watch,
        setValue,
        formState: { errors },
    } = useForm<FormValue>({
        mode: 'all',
        defaultValues: {},
    });

    const authData = useAuth();

    const backdrop = useBackdrop();

    const { isLoading } = authData;

    const handleChangePassword = async (data: ChangePassword) => {
        backdrop.setTrue();
        await accountController
            .changePassword(data)
            .then(() => {
                pushSuccess('Password changed successfully');
                props.onClose?.();
                reset({});
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    useEffect(() => {
        if (!open) return;
    }, [open]);
    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        minWidth: '200px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">Change password</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={isMediumMD ? 120 : 150}
            desc={
                <Stack direction={'column'} gap={2}>
                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="Password" required lineHeight={0} />
                        <Controller
                            control={control}
                            name="oldPassword"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Password is required',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <BaseTextField
                                        {...field}
                                        value={field.value}
                                        onChange={field.onChange}
                                        type={passwordVisible[field.name as any] ? 'text' : 'password'}
                                        error={!!errors.oldPassword?.message}
                                        placeholder="Password"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PasswordLockIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onMouseDown={handleMouseDownPassword}
                                                        onClick={() => handleClickShowPassword(field.name)}
                                                        edge="end"
                                                        sx={{
                                                            '& svg': {
                                                                width: '18px',
                                                                height: '18px',
                                                            },
                                                        }}
                                                    >
                                                        {passwordVisible[field.name as any] ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px ',
                                            },
                                        }}
                                    />
                                    <Typography ml={2} variant="body2" color={color.darkDanger}>
                                        {errors.oldPassword?.message}
                                    </Typography>
                                </>
                            )}
                        />
                    </Stack>
                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="New password" required lineHeight={0} />
                        <Controller
                            control={control}
                            name="newPassword"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'New password is required',
                                },
                                validate: (value: string) => {
                                    if (watch().confirmNewPassword !== value) {
                                        return 'Passwords do not match';
                                    }
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <BaseTextField
                                        {...field}
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            field.onChange(value);
                                            setValue('confirmNewPassword', watch().confirmNewPassword, {
                                                shouldValidate: true,
                                            });
                                        }}
                                        type={passwordVisible[field.name as any] ? 'text' : 'password'}
                                        error={!!errors.newPassword?.message}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PasswordLockIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onMouseDown={handleMouseDownPassword}
                                                        onClick={() => handleClickShowPassword(field.name)}
                                                        edge="end"
                                                        sx={{
                                                            '& svg': {
                                                                width: '18px',
                                                                height: '18px',
                                                            },
                                                        }}
                                                    >
                                                        {passwordVisible[field.name as any] ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px ',
                                            },
                                        }}
                                        placeholder="New password"
                                    />
                                    <Typography ml={2} variant="body2" color={color.darkDanger}>
                                        {errors.newPassword?.message}
                                    </Typography>
                                </>
                            )}
                        />
                    </Stack>
                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="Confirm password" required lineHeight={0} />
                        <Controller
                            control={control}
                            name="confirmNewPassword"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Confirm password is required',
                                },
                                validate: (value: string) => {
                                    if (watch().newPassword !== value) {
                                        return 'Passwords do not match';
                                    }
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <BaseTextField
                                        {...field}
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            field.onChange(value);
                                            setValue('newPassword', watch().newPassword, {
                                                shouldValidate: true,
                                            });
                                        }}
                                        type={passwordVisible[field.name as any] ? 'text' : 'password'}
                                        error={!!errors.confirmNewPassword?.message}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PasswordLockIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onMouseDown={handleMouseDownPassword}
                                                        onClick={() => handleClickShowPassword(field.name)}
                                                        edge="end"
                                                        sx={{
                                                            '& svg': {
                                                                width: '18px',
                                                                height: '18px',
                                                            },
                                                        }}
                                                    >
                                                        {passwordVisible[field.name as any] ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                padding: '10px ',
                                            },
                                        }}
                                        placeholder="Confirm password"
                                    />
                                    <Typography ml={2} variant="body2" color={color.darkDanger}>
                                        {errors.confirmNewPassword?.message}
                                    </Typography>
                                </>
                            )}
                        />
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            reset({});
                            clearErrors();
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{
                            justifyContent: isLoading ? 'space-between' : 'center',
                            borderRadius: '8px',
                        }}
                        loading={isLoading}
                        type="submit"
                        loadingPosition="center"
                        startIcon={isLoading ? <></> : undefined}
                        onClick={handleSubmit(async (data: FormValue) => {
                            await handleChangePassword({
                                newPassword: data.newPassword,
                                oldPassword: data.oldPassword,
                            });
                        })}
                        variant="contained"
                    >
                        Confirm
                    </LoadingButton>
                </Stack>
            }
        />
    );
}
