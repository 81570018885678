import React from 'react';
type Props = {
    color: string;
};
export default function AddLink(props: Props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.80094 6.43551C9.80094 5.97527 9.42785 5.60217 8.96761 5.60217C8.50737 5.60217 8.13428 5.97527 8.13428 6.43551V8.10217H6.46761C6.00737 8.10217 5.63428 8.47527 5.63428 8.93551C5.63428 9.39574 6.00737 9.76884 6.46761 9.76884H8.13428V11.4355C8.13428 11.8957 8.50737 12.2688 8.96761 12.2688C9.42785 12.2688 9.80094 11.8957 9.80094 11.4355V9.76884H11.4676C11.9278 9.76884 12.3009 9.39574 12.3009 8.93551C12.3009 8.47527 11.9278 8.10217 11.4676 8.10217H9.80094V6.43551Z"
                fill={props.color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.96761 0.602173C4.36524 0.602173 0.634277 4.33313 0.634277 8.93551C0.634277 13.5379 4.36524 17.2688 8.96761 17.2688C13.57 17.2688 17.3009 13.5379 17.3009 8.93551C17.3009 4.33313 13.57 0.602173 8.96761 0.602173ZM2.30094 8.93551C2.30094 5.25361 5.28571 2.26884 8.96761 2.26884C12.6495 2.26884 15.6343 5.25361 15.6343 8.93551C15.6343 12.6174 12.6495 15.6022 8.96761 15.6022C5.28571 15.6022 2.30094 12.6174 2.30094 8.93551Z"
                fill={props.color}
            />
        </svg>
    );
}
