import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '../assets/drawer-icon/LogoutIcon';
import useMedia from '../hook/useMedia';
import ItemDrawer from './drawer/DrawerItem';
import { DrawerItem, DrawerPage, useDrawerContext } from './drawer/useDrawer';
import { IPopUp } from './hooks/usePopUp';
import { checkPermissions } from 'helper/permissions';
import { TypeRole } from 'submodules/nail-supplier-base-model/models/Role';
import { useAuth } from 'hook/useAuth';

type Props = IPopUp & {
    openPopup: boolean;
    popUpWarning: {
        setTrue: () => void;
        open: boolean;
        onClose: () => void;
        onConfirm: (() => void) | undefined;
    };
};

export default function ListItemMenuLeft(props: Props) {
    let navigate = useNavigate();

    const drawerContext = useDrawerContext();
    const { ListDrawItem, activeItem, setActiveItem, setOpenPopup } = drawerContext;

    const { isMediumMD } = useMedia();
    const authData = useAuth();
    const { isCheckUIEmployee, isCheckUICustomer } = authData;

    const filteredListDrawItem: DrawerItem[] = [];

    if (isCheckUIEmployee) {
        const listContentItem = ListDrawItem.find((item) => item.id === DrawerPage.ListContent);
        if (listContentItem) {
            filteredListDrawItem.push(listContentItem);
        }
    }

    if (isCheckUICustomer) {
        const myShopItem = ListDrawItem.find((item) => item.id === DrawerPage.MyShop);
        // const facebookManagementItem = ListDrawItem.find((item) => item.id === DrawerPage.FacebookManagement);
        // if (myShopItem && facebookManagementItem) {
        //     filteredListDrawItem.push(myShopItem);
        //     filteredListDrawItem.push(facebookManagementItem);
        // }
        if (myShopItem) {
            filteredListDrawItem.push(myShopItem);
        }
    }

    return (
        <>
            <Grid
                container
                sx={{
                    minHeight: `${isMediumMD ? '90%' : 'calc(100vh - 80px)'}`,
                    maxWidth: '100%',
                    mt: '64px',
                }}
            >
                <Grid item xs={12}>
                    {filteredListDrawItem.map((item, index) => {
                        const isActive = item.id === activeItem;
                        return (
                            <ItemDrawer
                                key={index}
                                icon={item.icon}
                                isActive={isActive}
                                label={item.label}
                                open={props.openPopup}
                                onClick={() => {
                                    setActiveItem(item.id);
                                    navigate(item.path);
                                    setOpenPopup(false);
                                }}
                            />
                        );
                    })}
                </Grid>
                <Grid item xs={12} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Box width={isCheckUIEmployee ? '0px' : '100%'}></Box>
                    <ItemDrawer
                        key={99}
                        icon={<LogoutIcon className="svg-item-Menu" />}
                        isActive={false}
                        label={'Logout'}
                        open={props.openPopup}
                        onClick={() => props.popUpWarning.setTrue()}
                    />
                </Grid>
            </Grid>
        </>
    );
}
