import { CloudUploadOutlined } from '@mui/icons-material';
import { Button, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import AddLink from 'assets/jsx-icon/AddLink';
import RemoveImgIcon from 'assets/jsx-icon/RemoveImgIcon';
import UploadIMG from 'assets/jsx-icon/UploadIMG';
import { DrawerPage, useDrawerContext } from 'components/drawer/useDrawer';
import usePopUp from 'components/hooks/usePopUp';
import PopUpWarning from 'components/PopUpWarning';
import { pushError, pushSuccess } from 'components/toast';
import { postContentHttpController, upsertPostController } from 'controllers';
import useMedia from 'hook/useMedia';
import { url } from 'inspector';
import { useBackdrop } from 'providers/BackdropProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { PostContentWithFiles } from 'submodules/nail-supplier-base-model/models/PostContent';
import color from 'theme/Colors';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import PictureIcon from 'assets/jsx-icon/PictureIcon';
import RequiredTitle from 'components/RequiredTitle';

export interface urls {
    url: string;
    id?: number;
}
export default function PostContentPage() {
    const { isMediumMD } = useMedia();
    const drawerContext = useDrawerContext();
    const [imageUrls, setImageUrls] = useState<urls[]>([]);
    const [idImg, setIdImg] = useState<number>(1);
    const [contentProps, setContentProps] = useState<PostContentWithFiles>({
        Content: '',
        Files: [],
    });
    const isCheckLengthContent = contentProps.Content.length;
    const backdrop = useBackdrop();

    const popUpRemoveImage = usePopUp();

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;

        if (selectedFiles) {
            const files = Array.from(selectedFiles);
            setContentProps((prev) => ({ ...prev, Files: files }));

            const urls: string[] = files.map((file) => URL.createObjectURL(file));

            const arrayImage = urls.map((url) => {
                const id = uuidv4();
                return {
                    id: Number(id),
                    url: url,
                };
            }) as urls[];

            setImageUrls(arrayImage);
            event.target.value = '';
        }
    };

    const { setActiveItem } = drawerContext;
    useEffect(() => {
        setActiveItem(DrawerPage.PostContent);
    }, []);

    useEffect(() => {
        if (!popUpRemoveImage.open) return;
    }, [popUpRemoveImage.open]);

    const postOrUpdateContent = async (id?: number) => {
        const formData = new FormData();
        backdrop.setTrue();

        if (contentProps.Files) {
            contentProps.Files.forEach((file) => {
                formData.append('Files', file);
            });
        }
        formData.append('Content', contentProps.Content);
        if (id) {
            formData.append('Id', String(id));
        }

        await upsertPostController
            .upsertPost(formData)
            .then((res) => {
                if (id !== 0) {
                    // setListContent((prev) => ({ ...prev, rows: res }));
                    pushSuccess('Content has been updated successfully');
                } else {
                    pushSuccess('Content has been added successfully');
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setImageUrls([]);
                setContentProps((prev) => ({ ...prev, Content: '' }));
                backdrop.setFalse();
            });
    };

    const handleRemoveImage = (idImg: number, event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation();

        setImageUrls((prevUrls) => prevUrls.filter((url, index) => url.id !== idImg));

        popUpRemoveImage.onClose();
    };

    return (
        <Stack direction="column" justifyContent={'space-between'} mb={3}>
            <Stack my={isMediumMD ? 2 : 4} spacing={isMediumMD ? 1 : 2} direction={'column'}>
                <Typography variant="h2">Post information</Typography>
            </Stack>
            <Stack bgcolor={color.white} borderRadius={1} p={'12px 16px'}>
                <Stack direction={'column'} spacing={1}>
                    <RequiredTitle title="Post content" required lineHeight={0} />
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        error={isCheckLengthContent > 2000 ? true : false}
                        sx={{
                            '& .MuiInputBase-input': {
                                padding: 0,
                                borderRadius: 0,
                            },
                        }}
                        rows={10}
                        value={contentProps.Content}
                        onChange={(e) => setContentProps((prev) => ({ ...prev, Content: e.target.value }))}
                        placeholder="Article content"
                    />
                    <Typography
                        textAlign={'end'}
                        color={isCheckLengthContent > 2000 ? color.danger : color.textPrimary}
                    >
                        {isCheckLengthContent} / 2000
                    </Typography>
                </Stack>

                <Divider orientation="horizontal" flexItem color={color.white} sx={{ display: 'flex', marginY: 2 }} />

                <Stack direction={'column'} my={2} spacing={4} width={'100%'} margin={'auto'}>
                    <Stack direction={'column'} my={2} spacing={1} width={'100%'}>
                        <RequiredTitle title="Image" required lineHeight={0} />
                        <Stack
                            component="label"
                            alignItems={'center'}
                            p={2}
                            sx={{
                                backgroundColor: 'transparent',
                                border: `2px dashed ${color.containedButtonBg}`,
                                borderRadius: '8px',
                            }}
                        >
                            <IconButton
                                component="label"
                                role={undefined}
                                tabIndex={-1}
                                sx={{
                                    alignItems: 'center',
                                    backgroundColor: color.grey200,
                                    border: `5px solid ${color.grey100}`,
                                    width: '48px',
                                    height: '48px',
                                    marginBottom: '12px',
                                }}
                            >
                                <UploadIMG />
                                <VisuallyHiddenInput type="file" accept="image/*" onChange={handleFileChange} />
                            </IconButton>
                            <Typography variant="h3" color={color.primary}>
                                Click to upload
                            </Typography>
                            <Typography variant="body2" color={color.secondary}>
                                SVG, PNG, JPG or GIF
                            </Typography>
                        </Stack>
                        <Stack
                            alignItems={'center'}
                            fontSize={'24px'}
                            bgcolor={color.grey100}
                            borderRadius={1}
                            p={'8px 16px'}
                        >
                            <Grid
                                container
                                xs={12}
                                justifyContent={
                                    isMediumMD
                                        ? imageUrls.length > 3
                                            ? 'start'
                                            : 'space-evenly'
                                        : imageUrls.length > 6
                                        ? 'start'
                                        : 'space-evenly'
                                }
                            >
                                {imageUrls.length > 0 ? (
                                    imageUrls.map((url, index) => {
                                        return (
                                            <Grid
                                                item
                                                padding={1}
                                                xs={4}
                                                md={2}
                                                key={index}
                                                style={{ position: 'relative' }}
                                            >
                                                <img
                                                    src={url.url}
                                                    alt={`Uploaded ${index}`}
                                                    style={{
                                                        display: 'block',
                                                        objectFit: 'cover',
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: '8px',
                                                        border: '1px solid #ddd',
                                                        padding: '5px',
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px',
                                                    }}
                                                    onMouseUp={(e) => {
                                                        e.stopPropagation();
                                                        popUpRemoveImage.setTrue();
                                                        setIdImg(url.id!);
                                                    }}
                                                >
                                                    <RemoveImgIcon />
                                                </IconButton>
                                            </Grid>
                                        );
                                    })
                                ) : (
                                    <Stack p={4}>
                                        <PictureIcon />
                                    </Stack>
                                )}
                            </Grid>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack alignItems={'end'} mt={2}>
                    <Button
                        variant="contained"
                        disabled={imageUrls.length === 0 || contentProps.Content === '' || isCheckLengthContent > 2000}
                        sx={{
                            minWidth: isMediumMD ? '130px' : '170px',
                        }}
                        onClick={() => postOrUpdateContent()}
                    >
                        Confirm
                    </Button>
                </Stack>
            </Stack>
            <PopUpWarning
                {...popUpRemoveImage}
                onConfirm={() => {
                    handleRemoveImage(idImg);
                }}
                title="Delete"
                message="Are you sure you want to delete?"
                responsiveWidthButton={isMediumMD ? 120 : 150}
            />
        </Stack>
    );
}
export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
