import React from 'react';

export default function CopyIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6668 5.83325H7.3335C6.50507 5.83325 5.8335 6.50482 5.8335 7.33325V12.6666C5.8335 13.495 6.50507 14.1666 7.3335 14.1666H12.6668C13.4953 14.1666 14.1668 13.495 14.1668 12.6666V7.33325C14.1668 6.50482 13.4953 5.83325 12.6668 5.83325Z"
                stroke="#85858A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.3335 3H6.66683C7.6379 3 8.26563 3.00213 8.7281 3.06431C9.1619 3.12262 9.29783 3.21677 9.37396 3.29289C9.45003 3.36902 9.54423 3.50497 9.6025 3.93871C9.64916 4.28559 9.66203 4.72541 9.6655 5.33333H10.6657C10.6576 3.88331 10.5933 3.09804 10.081 2.58579C9.49523 2 8.55243 2 6.66683 2H5.3335C3.44788 2 2.50507 2 1.91928 2.58579C1.3335 3.17157 1.3335 4.11438 1.3335 6V7.33333C1.3335 9.21893 1.3335 10.1617 1.91928 10.7475C2.50507 11.3333 3.44788 11.3333 5.3335 11.3333V10.3333C4.36242 10.3333 3.73471 10.3312 3.2722 10.269C2.83846 10.2107 2.70252 10.1165 2.62639 10.0405C2.55026 9.96433 2.45612 9.8284 2.3978 9.3946C2.33562 8.93213 2.3335 8.3044 2.3335 7.33333V6C2.3335 5.02892 2.33562 4.40121 2.3978 3.93871C2.45612 3.50497 2.55026 3.36902 2.62639 3.29289C2.70252 3.21677 2.83846 3.12262 3.2722 3.06431C3.73471 3.00213 4.36242 3 5.3335 3Z"
                fill="#85858A"
            />
        </svg>
    );
}
