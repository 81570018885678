import { Paging } from '@Core/Paging';
import { Typography } from '@mui/material';
import { DrawerPage, useDrawerContext } from 'components/drawer/useDrawer';
import usePopUp from 'components/hooks/usePopUp';
import { pushError, pushSuccess } from 'components/toast';
import { postContentHttpController, upsertPostController } from 'controllers';
import useMedia from 'hook/useMedia';
import useScrollToTop from 'hook/useScrollToTop';
import { useBackdrop } from 'providers/BackdropProvider';
import { createContext, useContext, useEffect, useState } from 'react';
import { PostContent, PostContentWithFiles } from 'submodules/nail-supplier-base-model/models/PostContent';

const initPaging = {
    page: 1,
    pageSize: 4,
    rows: [],
    total: 0,
    totalPages: 0,
};

export type Filter = {
    page: number;
    search?: string;
    from?: Date;
    to?: Date;
    post?: Paging<PostContent>;
};

export interface urls {
    url: string;
    id: string;
}
export default function useContentProvider() {
    const drawerContext = useDrawerContext();
    const [listContent, setListContent] = useState<Paging<PostContent>>({ ...initPaging });
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [idListContent, setIdListContent] = useState<number>(0);
    const [imageUrls, setImageUrls] = useState<urls[]>([]);
    const [contentProps, setContentProps] = useState<PostContentWithFiles>({
        Content: '',
        Files: [],
    });
    const { isMediumMD } = useMedia();
    const popUpEditContent = usePopUp();
    const popUpDeleteContent = usePopUp();
    const backdrop = useBackdrop();
    useScrollToTop();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { setActiveItem } = drawerContext;
    useEffect(() => {
        setActiveItem(DrawerPage.ListContent);
    }, []);

    const getListContent = async () => {
        setIsLoading(true);
        await postContentHttpController
            .list({
                page: listContent.page,
                pageSize: listContent.pageSize,
            })
            .then((res) => {
                setListContent(res);
            })
            .catch((error) => {
                pushError(error.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteContent = async (id: number) => {
        backdrop.setTrue();
        await postContentHttpController
            .delete(id)
            .then(async () => {
                const filteredList = listContent.rows.filter((item) => item.Id !== id);
                setListContent((prev) => ({
                    ...prev,
                    rows: filteredList,
                    total: filteredList.length,
                }));
                pushSuccess('Article has been removed successfully');
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                popUpDeleteContent.onClose();
                backdrop.setFalse();
                setContentProps((prev) => ({
                    ...prev,
                    Content: '',
                }));
                setIdListContent(0);
                setImageUrls([]);
            });
    };

    const postOrUpdateContent = async (id?: number) => {
        const formData = new FormData();
        backdrop.setTrue();
        if (contentProps.Files.length) {
            contentProps.Files.forEach((file) => {
                formData.append('Files', file);
            });
        }
        formData.append('Content', contentProps.Content);
        if (id) {
            formData.append('Id', String(id));
        }

        await upsertPostController
            .upsertPost(formData)
            .then(async (res) => {
                if (id !== undefined) {
                    pushSuccess('Content has been updated successfully');
                } else {
                    pushSuccess('Content has been added successfully');
                }
                setListContent((prev) => ({
                    ...prev,
                    rows: res,
                    total: res.length,
                    page: 1,
                    pageSize: 4,
                    totalPages: Math.ceil(res.length / listContent.pageSize),
                }));
                popUpEditContent.onClose?.();
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
                setContentProps((prev) => ({
                    ...prev,
                    Content: '',
                    Files: [],
                }));
                setIdListContent(0);
                setImageUrls([]);
            });
    };

    const handleMenuListContent = async (id?: number) => {
        if (id === 1) {
            popUpEditContent.setTrue();
            setAnchorElUser(null);
        } else {
            popUpDeleteContent.setTrue();
            setAnchorElUser(null);
        }
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        getListContent();
    }, [listContent.page]);

    const [expanded, setExpanded] = useState(false);

    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    useScrollToTop();

    const closeModal = () => {
        setSelectedImage(null);
    };

    const handleModalClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const formatText = (text: string) => {
        return text.split('\n').map((paragraph, index) => <Typography key={index}>{paragraph}</Typography>);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;

        if (selectedFiles) {
            const files = Array.from(selectedFiles);
            setContentProps((prev) => ({ ...prev, Files: files }));

            const urls: string[] = files.map((file) => URL.createObjectURL(file));

            const arrayImage = urls.map((url) => {
                return {
                    url: url,
                };
            }) as urls[];

            setContentProps((prev) => ({
                ...prev,
                PostFiles: [
                    {
                        BlobName: arrayImage[0].url || '',
                        ContentId: contentProps?.PostFiles?.[0].ContentId || 1,
                    },
                ],
            }));

            setImageUrls(arrayImage);
            event.target.value = '';
        }
    };

    return {
        listContent,
        handleCloseUserMenu,
        handleMenuListContent,
        handleFileChange,
        popUpEditContent,
        handleDeleteContent,
        anchorElUser,
        popUpDeleteContent,
        isMediumMD,
        setIdListContent,
        idListContent,
        isLoading,
        setAnchorElUser,
        setListContent,
        formatText,
        toggleExpand,
        selectedImage,
        setSelectedImage,
        closeModal,
        handleModalClick,
        expanded,
        backdrop,
        setContentProps,
        postOrUpdateContent,
        contentProps,
        setImageUrls,
        imageUrls,
    };
}
type ContentContextType = ReturnType<typeof useContentProvider>;

export const ContentContext = createContext<ContentContextType>({} as ContentContextType);

export const useContent = () => useContext(ContentContext);
