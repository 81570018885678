import { DrawerPage, useDrawerContext } from 'components/drawer/useDrawer';
import useDebounce from 'components/hooks/useDebounce';
import usePopUp from 'components/hooks/usePopUp';
import { pushError, pushSuccess } from 'components/toast';
import { linkReviewController, shopController } from 'controllers';
import useMedia from 'hook/useMedia';
import { cloneDeep } from 'lodash';
import { useBackdrop } from 'providers/BackdropProvider';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateShopName } from 'submodules/nail-supplier-base-model/controllers/IShopHttpController';
import { ChangePassword } from 'submodules/nail-supplier-base-model/models/Account';
import { LinkReview } from 'submodules/nail-supplier-base-model/models/LinkReview';
import { Shop } from 'submodules/nail-supplier-base-model/models/Shop';

export default function useShopInfoProvider() {
    const drawerContext = useDrawerContext();
    const { setActiveItem } = drawerContext;

    const backdrop = useBackdrop();
    const { isMediumMD } = useMedia();

    const popUpEditOrAdd = usePopUp();
    const popUpConfirmRemoveFormEdit = usePopUp();
    const popUpChangeNameShop = usePopUp();

    const [formLinkReviews, setFormLinkReviews] = useState<LinkReview[]>([]);
    const [shopDetail, setShopDetail] = useState<Partial<Shop>[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [idForm, setIdForm] = useState<number>(0);
    const [idShop, setIdShop] = useState<number>(0);
    const [filterSearch, setFilterSearch] = useState<string>('');

    const getListShopDetail = async () => {
        setIsLoading(true);
        await shopController
            .getList()
            .then((res) => {
                setShopDetail(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getListShopDetail();
        setActiveItem(DrawerPage.MyShop);
    }, []);

    const handleKeywordKeyPress = (e: any, formLink: Partial<LinkReview>) => {
        if (e.key == 'Enter') {
            handleEditOrAddlink(formLink).then(() => {
                popUpEditOrAdd.onClose();
            });
        }
    };

    const handleRemoveForm = async (id: number) => {
        backdrop.setTrue();
        await linkReviewController
            .delete(id)
            .then(() => {
                setFormLinkReviews((prev) =>
                    prev.filter((item) => {
                        return item.Id !== id;
                    })
                );
                pushSuccess('Link has been removed successfully');
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIdForm(0);
                backdrop.setFalse();
            });
    };

    const handleEditOrAddlink = async (formLink: Partial<LinkReview>) => {
        backdrop.setTrue();
        await linkReviewController
            .upsertLink(formLink)
            .then((res) => {
                if (idForm !== 0) {
                    const linkResponse = res.find((item) => item.Id === formLink.Id);
                    const updatedLinkReviews = formLinkReviews.map((item) => {
                        if (item.Id === linkResponse?.Id) {
                            return { ...item, ...linkResponse };
                        } else {
                            return item;
                        }
                    });
                    setFormLinkReviews(updatedLinkReviews);
                    pushSuccess('The link review has been updated successfully');
                } else {
                    setFormLinkReviews([...formLinkReviews, res[res.length - 1]]);
                    pushSuccess('The link review has been added successfully');
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIdForm(0);
                backdrop.setFalse();
            });
    };

    const handleChangeShopName = async (data: UpdateShopName) => {
        backdrop.setTrue();
        await shopController
            .updateShopName(data)
            .then((res) => {
                setShopDetail(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    return {
        isLoading,
        isMediumMD,
        idForm,
        setIdForm,
        formLinkReviews,
        setFormLinkReviews,
        popUpEditOrAdd,
        popUpConfirmRemoveFormEdit,
        popUpChangeNameShop,
        handleRemoveForm,
        handleEditOrAddlink,
        handleChangeShopName,
        handleKeywordKeyPress,
        filterSearch,
        setFilterSearch,

        shopDetail,
        setIdShop,
        idShop,
    };
}
type ShopInfoContextType = ReturnType<typeof useShopInfoProvider>;

export const ShopInfoContext = createContext<ShopInfoContextType>({} as ShopInfoContextType);

export const useShop = () => useContext(ShopInfoContext);
