import { CloudUploadOutlined } from '@mui/icons-material';
import { Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { BaseTextField } from 'components/BaseTextField';
import { IPopUp } from 'components/hooks/usePopUp';
import PopUpBase from 'components/PopUpBase';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UpdateShopName } from 'submodules/nail-supplier-base-model/controllers/IShopHttpController';
import color from 'theme/Colors';
import { useShop } from './ShopInfoProvider';
import UploadIMG from 'assets/jsx-icon/UploadIMG';
import { urls, VisuallyHiddenInput } from 'pages/contentPage/PostContentPage';
import RemoveImgIcon from 'assets/jsx-icon/RemoveImgIcon';
import PictureIcon from 'assets/jsx-icon/PictureIcon';
import RequiredTitle from 'components/RequiredTitle';
type Props = Omit<IPopUp, 'onConfirm'> & {};

interface FormValue extends UpdateShopName {
    shopAddress: string;
    openHoursDaily: string;
    imageShop: string;
}

export default function PopUpChangeNameShop(props: Props) {
    const { open, onClose } = props;
    const shopInfo = useShop();
    const { isMediumMD, idShop, shopDetail, handleChangeShopName } = shopInfo;
    const [imageUrls, setImageUrls] = useState<urls[]>([]);
    const nameShop = shopDetail.filter((item) => item.Id === idShop);

    const {
        control,
        handleSubmit,
        reset,
        clearErrors,
        setValue,
        formState: { errors, isValid, isDirty },
    } = useForm<FormValue>({
        mode: 'onSubmit',
        defaultValues: {},
    });

    useEffect(() => {
        if (!open) return;
        setValue('name', nameShop[0].Name || '');
    }, [open]);

    const disabled = !(isValid && isDirty);

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        minWidth: '200px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">Shop information</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={isMediumMD ? 120 : 150}
            desc={
                <Stack direction={'column'} spacing={2}>
                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="Name" required lineHeight={0} />
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Name shop is required',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    placeholder={'Enter your shop name'}
                                    autoFocus={true}
                                    value={field.value}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                    maxLength={30}
                                    onChange={field.onChange}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px ',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Stack>

                    {/* <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="Address" required lineHeight={0} />
                        <Controller
                            control={control}
                            name="shopAddress"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Address shop is required',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    placeholder={'Enter your address'}
                                    autoFocus={false}
                                    value={field.value}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                    maxLength={30}
                                    onChange={field.onChange}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px ',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Stack>

                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title="Open hour" required lineHeight={0} />
                        <Controller
                            control={control}
                            name="openHoursDaily"
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Open hours is required',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    placeholder={'Enter your open hours'}
                                    autoFocus={false}
                                    value={field.value}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                    maxLength={30}
                                    onChange={field.onChange}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: '10px ',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Stack> */}

                    {/* <Stack direction={'column'} spacing={1}>
                        <Typography>Avatar</Typography>
                        <Controller
                            control={control}
                            name="imageShop"
                            render={({ field }) => (
                                <>
                                    <label htmlFor="file-input">
                                        <Stack
                                            width={'100%'}
                                            sx={{
                                                border: `2px dashed ${color.priText}`,
                                                borderRadius: '10px',
                                                padding: '50px 25px ',
                                                cursor: 'pointer',
                                            }}
                                            alignItems={'center'}
                                            fontSize={'24px'}
                                        >
                                            {fileName ? (
                                                <Typography variant="body2">{fileName}</Typography>
                                            ) : (
                                                <CloudUploadOutlined />
                                            )}
                                        </Stack>
                                    </label>
                                    <TextField
                                        id="file-input"
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={(e: any) => {
                                            const file = e.target.files && e.target.files[0];
                                            if (file) {
                                                setFileName(file.name);
                                                field.onChange(file);
                                            }
                                        }}
                                    />
                                </>
                            )}
                        />
                    </Stack> */}
                    {/* <Stack direction={'column'} my={2} spacing={2} width={'100%'}>
                        <Typography variant="h5">Image</Typography>

                        <Controller
                            control={control}
                            name="imageShop"
                            render={({ field }) => (
                                <Stack
                                    {...field}
                                    component="label"
                                    alignItems={'center'}
                                    p={2}
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: `2px dashed ${color.containedButtonBg}`,
                                        borderRadius: '8px',
                                    }}
                                >
                                    <IconButton
                                        component="label"
                                        role={undefined}
                                        tabIndex={-1}
                                        sx={{
                                            alignItems: 'center',
                                            backgroundColor: color.grey200,
                                            border: `5px solid ${color.grey100}`,
                                            width: '48px',
                                            height: '48px',
                                            marginBottom: '12px',
                                        }}
                                    >
                                        <UploadIMG />
                                        <VisuallyHiddenInput
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            onChange={(e: any) => {
                                                const file = e.target.files && e.target.files[0];
                                                if (file) {
                                                    field.onChange(file);
                                                }
                                            }}
                                        />
                                    </IconButton>
                                    <Typography variant="h3" color={color.primary}>
                                        Click to upload
                                    </Typography>
                                    <Typography variant="body2" color={color.secondary}>
                                        SVG, PNG, JPG or GIF
                                    </Typography>
                                </Stack>
                            )}
                        />
                        <Stack
                            alignItems={'center'}
                            fontSize={'24px'}
                            bgcolor={color.grey100}
                            borderRadius={1}
                            p={'8px 16px'}
                        >
                            <Grid
                                container
                                xs={12}
                                justifyContent={
                                    isMediumMD
                                        ? imageUrls.length > 3
                                            ? 'start'
                                            : 'space-evenly'
                                        : imageUrls.length > 6
                                        ? 'start'
                                        : 'space-evenly'
                                }
                            >
                                {imageUrls.length > 0 ? (
                                    imageUrls.map((url, index) => {
                                        return (
                                            <Grid
                                                item
                                                padding={1}
                                                xs={4}
                                                md={2}
                                                key={index}
                                                style={{ position: 'relative' }}
                                            >
                                                <img
                                                    src={url.url}
                                                    alt={`Uploaded ${index}`}
                                                    style={{
                                                        display: 'block',
                                                        objectFit: 'cover',
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: '8px',
                                                        border: '1px solid #ddd',
                                                        padding: '5px',
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px',
                                                    }}
                                                    onMouseUp={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <RemoveImgIcon />
                                                </IconButton>
                                            </Grid>
                                        );
                                    })
                                ) : (
                                    <Stack p={4}>
                                        <PictureIcon />
                                    </Stack>
                                )}
                            </Grid>
                        </Stack>
                    </Stack> */}
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={disabled}
                        onClick={handleSubmit(async (data: FormValue) => {
                            await handleChangeShopName({
                                name: data.name,
                                shopId: idShop ?? 0,
                                // shopAddress: data.shopAddress,
                                // openHoursDaily: data.openHoursDaily,
                            });
                            props.onClose?.();
                            reset({});
                        })}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
