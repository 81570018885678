import React, { SVGProps } from 'react';
import color from 'theme/Colors';

type Props = {
    widthAndHeight?: number;
} & SVGProps<SVGSVGElement>;
export default function ICDelete(props: Props) {
    return (
        <svg
            width={props.widthAndHeight ?? 12}
            height={props.widthAndHeight ?? 13}
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M-2.20477 -1.2704C-2.01724 -1.45787 -1.76294 -1.56318 -1.49777 -1.56318C-1.23261 -1.56318 -0.9783 -1.45787 -0.790772 -1.2704L5.50223 5.0226L11.7952 -1.2704C11.8875 -1.36591 11.9978 -1.44209 12.1198 -1.4945C12.2418 -1.54691 12.373 -1.57449 12.5058 -1.57565C12.6386 -1.5768 12.7703 -1.5515 12.8932 -1.50122C13.0161 -1.45094 13.1277 -1.37668 13.2216 -1.28279C13.3155 -1.1889 13.3898 -1.07725 13.44 -0.954349C13.4903 -0.831453 13.5156 -0.699773 13.5145 -0.566994C13.5133 -0.434215 13.4857 -0.302995 13.4333 -0.180991C13.3809 -0.0589869 13.3047 0.0513577 13.2092 0.143605L6.91623 6.4366L13.2092 12.7296C13.3914 12.9182 13.4922 13.1708 13.4899 13.433C13.4876 13.6952 13.3825 13.946 13.197 14.1314C13.0116 14.3168 12.7608 14.422 12.4986 14.4243C12.2364 14.4266 11.9838 14.3258 11.7952 14.1436L5.50223 7.8506L-0.790772 14.1436C-0.979374 14.3258 -1.23198 14.4266 -1.49417 14.4243C-1.75637 14.422 -2.00718 14.3168 -2.19259 14.1314C-2.378 13.946 -2.48317 13.6952 -2.48545 13.433C-2.48772 13.1708 -2.38693 12.9182 -2.20477 12.7296L4.08823 6.4366L-2.20477 0.143605C-2.39224 -0.043923 -2.49756 -0.298231 -2.49756 -0.563395C-2.49756 -0.82856 -2.39224 -1.08287 -2.20477 -1.2704Z"
                fill={color.danger}
            />
        </svg>
    );
}
