import React from 'react';
import color from 'theme/Colors';

export default function FacebookIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            id="facebook"
            className="icon-svg-fill"
            fill={color.grey600}
        >
            <path d="M19.7 23.5H4.3c-2.1 0-3.8-1.7-3.8-3.8V4.3C.5 2.2 2.2.5 4.3.5h15.3c2.1 0 3.8 1.7 3.8 3.8v15.3c.1 2.2-1.6 3.9-3.7 3.9zM4.3 1.5c-1.6 0-2.8 1.3-2.8 2.8v15.3c0 1.6 1.3 2.8 2.8 2.8h15.3c1.6 0 2.8-1.3 2.8-2.8V4.3c0-1.6-1.3-2.8-2.8-2.8H4.3z"></path>
            <path d="M13.1 19.2H9.7c-.3 0-.5-.2-.5-.5v-4.2H8c-.3 0-.5-.2-.5-.5v-3.4c0-.4.2-.6.5-.6h1.2V8.9c0-2 1.1-4.1 4.1-4.1H16c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5h-2c-.2 0-.3.1-.3.3v.9H16c.1 0 .3.1.4.2.1.1.1.2.1.4l-.3 3.4c0 .3-.2.5-.5.5h-2.1v4.2c0 .3-.2.5-.5.5zm-2.9-1h2.4V14c0-.3.2-.5.5-.5h2.1l.2-2.4h-2.2c-.3 0-.5-.2-.5-.5V9.1c0-.9.6-1.3 1.3-1.3h1.4v-2h-2.2c-2.8 0-3.1 2.2-3.1 3.1v1.6c0 .3-.2.5-.5.5H8.5v2.4h1.2c.3 0 .5.2.5.5v4.3z"></path>
            <path d="M14.9 11h-2.7c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2.7c.3 0 .5.2.5.5s-.3.5-.5.5z"></path>
        </svg>
    );
}
