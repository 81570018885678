/* eslint-disable react-hooks/exhaustive-deps */
import { Settings } from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import LogoutIcon from 'assets/drawer-icon/LogoutIcon';
import AddLink from 'assets/jsx-icon/AddLink';
import EditIcon from 'assets/jsx-icon/EditIcon';
import useDrawer from 'components/drawer/useDrawer';
import usePopUp from 'components/hooks/usePopUp';
import PopUpWarning from 'components/PopUpWarning';
import { pushError, pushSuccess } from 'components/toast';
import { fbConnectionHttpController, shopController } from 'controllers';
import useMedia from 'hook/useMedia';
import FacebookPage from 'pages/myShopPage/components/FacebookPage';
import PopUpChangePassword from 'pages/myShopPage/components/PopUpChangePassword';
import PopUpCreateNewShop from 'pages/myShopPage/components/PopUpCreateNewShop';
import { useBackdrop } from 'providers/BackdropProvider';
import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shop } from 'submodules/nail-supplier-base-model/models/Shop';
import color from '../../theme/Colors';
import MyDrawer from '../drawer/MyDrawer';
import PopUpErrorConnectFacebook from 'pages/myShopPage/components/PopUpErrorConnectFacebook';
import { FbAuth } from 'submodules/nail-supplier-base-model/models/FacebookConnection';
import { useAuth } from 'hook/useAuth';
import PopUpCreateNewLink from 'pages/myShopPage/components/PopUpCreateNewLink';
import PopUpBase from 'components/PopUpBase';
import { useForm } from 'react-hook-form';
import { appConfig } from 'configs';
import { LoadingButton } from '@mui/lab';
import PopUpConfirmCreateNewShop from 'pages/myShopPage/components/PopUpConfirmCreateNewShop';
import PopUpConfirmCreateNewLinkShop from 'pages/myShopPage/components/PopUpConfirmCreateNewLinkShop';

export interface FormValue {
    createNewShop: string;
    createLinkRedirect: string;
}
export default function MyAppBar() {
    const { isMediumMD, isMobileSM } = useMedia();

    const settings = [
        {
            id: 1,
            title: 'Change password',
            icon: <EditIcon />,
        },
        {
            id: 2,
            title: 'Logout',
            icon: <LogoutIcon />,
        },
    ];
    const drawerContext = useDrawer();
    const popUpChangePassword = usePopUp();
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const authData = useAuth();
    const { logout, isCheckUIEmployee, userName } = authData;

    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuSettings = async (id?: number, response?: any) => {
        if (id === 1) {
            popUpChangePassword.setTrue();
            setAnchorElUser(null);
        } else {
            drawerContext.popUpWarning.setTrue();
            setAnchorElUser(null);
        }
    };

    const backdrop = useBackdrop();

    const popUpCreateShop = usePopUp();
    const popUpConfirmCreateShop = usePopUp();
    const popUpCreateNewShopLink = usePopUp();
    const popUpConfirmCreateNewLinkShop = usePopUp();

    const [shopDetail, setShopDetail] = useState<Shop>();

    const handleCreateShopLink = async () => {
        backdrop.setTrue();
        await shopController
            .createShop()
            .then((res) => {
                popUpConfirmCreateShop.onClose();
                popUpCreateShop.setTrue();
                setShopDetail(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const {
        control,
        handleSubmit,
        reset,
        clearErrors,
        watch,
        setValue,
        formState: { errors },
    } = useForm<FormValue>({
        mode: 'all',
        defaultValues: {
            createLinkRedirect: `${appConfig.gateway.linkRedirect}`,
        },
    });

    useEffect(() => {
        if (watch().createNewShop) {
            const codeSplit = watch().createNewShop.split('/');
            setValue('createLinkRedirect', `${appConfig.gateway.linkRedirect}${codeSplit[codeSplit.length - 1]}`);
        } else {
            setValue('createLinkRedirect', `${appConfig.gateway.linkRedirect}`);
        }
    }, [watch().createNewShop]);

    const handlePasteValue = async () => {
        try {
            const pastedContent = await navigator.clipboard.readText();
            pushSuccess('Paste successfully');
            setValue('createNewShop', pastedContent.trim().replace(/\s/g, ''));
        } catch (error) {
            pushError('Failed to read clipboard:');
        }
    };

    return (
        <AppBar
            sx={{
                position: 'fixed',
                zIndex: (theme) => theme.zIndex.drawer + 100,
                width: '100vw',
                top: 0,
                background: color.appBarColor,
                boxShadow: 'none',
            }}
        >
            <Toolbar>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Stack flexDirection={'row'}>{isMediumMD ? <MyDrawer /> : <></>}</Stack>
                    {
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={2}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    color: color.grey600,
                                    px: '5px',
                                },
                                '& .MuiInputBase-input': {
                                    pr: '35px !important',
                                },
                            }}
                        >
                            {(userName === 'admin' || userName === 'hoangminh1' || userName === '247nailsupply') && (
                                <>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <Button
                                            onClick={() => {
                                                popUpCreateNewShopLink.setTrue();
                                            }}
                                            sx={{
                                                backgroundColor: 'transparent !important',
                                                color: color.grey200,
                                                padding: '5px',
                                                fontSize: '14px',
                                                '&:hover': {
                                                    color: color.white,
                                                },
                                            }}
                                            endIcon={<AddLink color={color.grey200} />}
                                        >
                                            Create new link
                                        </Button>
                                    </Stack>
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        color={color.white}
                                        sx={{ display: 'flex' }}
                                    />
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <Button
                                            onClick={async () => {
                                                popUpConfirmCreateShop.setTrue();
                                            }}
                                            sx={{
                                                backgroundColor: 'transparent !important',
                                                color: color.grey200,
                                                padding: '5px',
                                                fontSize: '14px',
                                                '&:hover': {
                                                    color: color.white,
                                                },
                                            }}
                                            endIcon={<AddLink color={color.grey200} />}
                                        >
                                            Create shop
                                        </Button>
                                    </Stack>
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        color={color.white}
                                        sx={{ display: 'flex' }}
                                    />
                                </>
                            )}

                            <Box sx={{ flexGrow: 0 }}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Typography variant="body1" color={color.white} display={'inline-block'} pr={1}>
                                        {userName ? userName : 'Admin'}
                                    </Typography>
                                    <Tooltip title="Settings">
                                        <IconButton
                                            onClick={handleOpenUserMenu}
                                            size="large"
                                            color="primary"
                                            sx={{
                                                p: 0,
                                                '& .MuiSvgIcon-root': {
                                                    color: `${color.white} !important`,
                                                },
                                            }}
                                        >
                                            <Settings
                                                sx={{
                                                    width: '32px',
                                                    height: '32px',
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {/* <FacebookPage /> */}
                                    {/* <FacebookPage setAnchorElUser={setAnchorElUser} /> */}
                                    {settings.map((setting, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={() => {
                                                handleMenuSettings(setting.id);
                                            }}
                                        >
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                                spacing={3}
                                                justifyContent={'space-between'}
                                            >
                                                {setting.icon}
                                                <Typography textAlign="center">{setting.title}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </Stack>
                    }
                </Stack>
            </Toolbar>
            <PopUpWarning
                {...drawerContext.popUpWarning}
                onConfirm={() => {
                    logout();
                    navigate('/sign-in');
                }}
                title="Logout"
                message="Are you sure you want to log out?"
                responsiveWidthButton={isMediumMD ? 120 : 150}
            />

            <PopUpChangePassword {...popUpChangePassword} />

            <PopUpCreateNewShop {...popUpCreateShop} shopDetail={shopDetail} />
            <PopUpConfirmCreateNewShop {...popUpConfirmCreateShop} handleCreateShopLink={handleCreateShopLink} />
            <PopUpCreateNewLink
                {...popUpCreateNewShopLink}
                reset={reset}
                clearErrors={clearErrors}
                watch={watch}
                control={control}
                errors={errors}
                handlePasteValue={handlePasteValue}
                popUpConfirmCreateNewLinkShop={popUpConfirmCreateNewLinkShop}
            />
            <PopUpConfirmCreateNewLinkShop
                {...popUpConfirmCreateNewLinkShop}
                reset={reset}
                watch={watch}
                handleSubmit={handleSubmit}
                popUpCreateNewShopLink={popUpCreateNewShopLink}
            />
        </AppBar>
    );
}
