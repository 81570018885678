import { BaseModel } from '@Core';
import { Shop } from './Shop';
import { Role } from './Role';
import { FbConnection } from './FacebookConnection';
import { PostSchedule } from './PostSchedule';

export interface Account extends BaseModel {
    UserName: string;
    Password: string;
    LoginCount?: number;
    Roles?: Role[];
    FbTokenValid?: boolean;
    FbConnections?: FbConnection[];
    PostSchedules?: PostSchedule[];
}

export interface RegisterProps extends Account {
    NFClink?: string;
    TypeRegister: TypeRegister;
}
export enum TypeRegister {
    Register,
    Connect,
}

export interface AccountShop extends BaseModel {
    AccountId: number;
    ShopId: number;
    Accounts?: Account;
    Shops?: Shop;
}
export interface ChangePassword {
    oldPassword: string;
    newPassword: string;
}
