import { Box, Grow, Stack } from '@mui/material';
import '../components/css/loading.css';

type Props = {
    isHeight: boolean;
};

export default function CircularLoading(props: Props) {
    const { isHeight } = props;
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" mt={10}>
            <Grow in={true} timeout={Math.min(500, 1000)}>
                <Box minHeight={isHeight ? '100%' : 'calc(100vh - 110px)'}>
                    <span className="loader"></span>
                </Box>
            </Grow>
        </Stack>
    );
}
