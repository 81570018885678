import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import PopUpBase from 'components/PopUpBase';
// import useAuthContext, { useAuthContext } from 'hook/useAuth';
import usePopUp from 'components/hooks/usePopUp';
import { pushError } from 'components/toast';
import { accountController } from 'controllers';
import { LocalStore } from 'helper/localStorage';
import useMedia from 'hook/useMedia';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Account, RegisterProps, TypeRegister } from 'submodules/nail-supplier-base-model/models/Account';
import MailIcon from '../../assets/jsx-icon/MailIcon';
import PasswordLockIcon from '../../assets/jsx-icon/PasswordLockIcon';
import TextNails247 from '../../assets/jsx-icon/TextNails247';
import { BaseTextField } from '../../components/BaseTextField';
import { loginIMG } from '../../configs/ImageFromEnv';
import color from '../../theme/Colors';

export const ID_TOKEN = 'idToken';
export const PATH_LOGIN_LOCATION = '/sign-in';
export const PATH_SIGN_UP_LOCATION = '/register';
export const PATH_CONNECT_LOCATION = '/connect';

export default function Login() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const linkCode = searchParams.get('code');
    const handleClickShowPassword = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm<Account>({
        mode: 'onSubmit',
        defaultValues: {},
    });

    const disabled = !(isValid && isDirty);
    const navigate = useNavigate();
    const { isMediumMD } = useMedia();

    const handleClickNavigate = () => {
        const { pathname, search } = location;
        switch (`${pathname}${search}`) {
            case `${PATH_SIGN_UP_LOCATION}?code=${linkCode}`:
                return (window.location.href = `${PATH_CONNECT_LOCATION}?code=${linkCode}`);

            case PATH_SIGN_UP_LOCATION:
                return (window.location.href = PATH_LOGIN_LOCATION);

            case PATH_LOGIN_LOCATION:
                return (window.location.href = PATH_SIGN_UP_LOCATION);

            case `${PATH_CONNECT_LOCATION}?code=${linkCode}`:
                return (window.location.href = `${PATH_SIGN_UP_LOCATION}?code=${linkCode}`);

            default:
                window.location.href = PATH_SIGN_UP_LOCATION;
        }
    };

    const getNameLabelButton = () => {
        switch (location.pathname) {
            case PATH_CONNECT_LOCATION:
                return 'Connect';
            case PATH_LOGIN_LOCATION:
                return 'Sign in';
            default:
                return 'Sign up';
        }
    };

    const getLocationLabelHaveAnAccount = () => {
        switch (true) {
            case location.pathname === PATH_LOGIN_LOCATION:
                return 'Sign up';
            case location.pathname === PATH_CONNECT_LOCATION:
                return 'Sign up';
            case !!linkCode:
                return 'Connect';
            case location.pathname === PATH_SIGN_UP_LOCATION:
                return 'Sign in';
            default:
                return 'Sign in';
        }
    };

    const popUpConfirmLogin = usePopUp();

    const handleLogin = async (data: Account) => {
        setIsLoading(true);
        await accountController
            .login(data)
            .then(async (res) => {
                localStorage.setItem(ID_TOKEN, res);
                window.location.href = '/list-content';
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleRegisterOrConnect = async (data: RegisterProps) => {
        setIsLoading(true);
        await accountController
            .registerOrConnectAccount(data)
            .then(() => {
                popUpConfirmLogin.setTrue();
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getWelcomeMessage = (linkCode: string) => {
        if (location.pathname === PATH_CONNECT_LOCATION) {
            return 'Connect';
        } else if (location.pathname === PATH_SIGN_UP_LOCATION || linkCode) {
            return 'Register';
        } else {
            return 'Welcome';
        }
    };

    let messageWelcome = getWelcomeMessage(linkCode ?? '');

    useEffect(() => {
        const token = LocalStore.getValue(ID_TOKEN);
        if (token) {
            navigate('/my-shop');
        }
    }, []);

    return (
        <Stack
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                minHeight: 'calc(100vh - 110px)',
            }}
            spacing={3}
            direction={'column'}
        >
            <Stack width={'100%'} alignItems={'center'} spacing={1}>
                <img src={loginIMG} alt="" width={80} height={60} />
                <TextNails247 />
            </Stack>

            <Typography variant="h2">{messageWelcome}</Typography>

            <Stack width={'90%'} maxWidth={'400px'}>
                <Controller
                    control={control}
                    name="UserName"
                    rules={{
                        required: {
                            value: true,
                            message: 'Username is required',
                        },
                        // pattern: {
                        //     value: /^[a-zA-Z0-9_-]+$/,
                        //     message: 'Username must contain only letters, numbers, hyphens, and underscores.',
                        // },
                    }}
                    render={({ field }) => (
                        <>
                            <BaseTextField
                                {...field}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: '100%',
                                }}
                                placeholder="Username"
                                error={!!errors.UserName?.message}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            <Typography ml={2} variant="body2" color={color.darkDanger}>
                                {errors.UserName?.message}
                            </Typography>
                        </>
                    )}
                />
            </Stack>

            <Stack width={'90%'} maxWidth={'400px'}>
                <Controller
                    control={control}
                    name="Password"
                    rules={{
                        required: {
                            value: true,
                            message: 'Password is required',
                        },
                    }}
                    render={({ field }) => (
                        <>
                            <BaseTextField
                                {...field}
                                value={field.value}
                                onChange={field.onChange}
                                type={passwordVisible ? 'text' : 'password'}
                                error={!!errors.Password?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordLockIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onMouseDown={handleMouseDownPassword}
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                                sx={{
                                                    '& svg': {
                                                        width: '18px',
                                                        height: '18px',
                                                    },
                                                }}
                                            >
                                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: '100%',
                                }}
                                placeholder="Password"
                            />
                            <Typography ml={2} variant="body2" color={color.darkDanger}>
                                {errors.Password?.message}
                            </Typography>
                        </>
                    )}
                />
            </Stack>

            <Stack width={'90%'} maxWidth={'400px'}>
                <LoadingButton
                    sx={{
                        justifyContent: isLoading ? 'space-between' : 'center',
                    }}
                    loading={isLoading}
                    loadingPosition="center"
                    variant="contained"
                    startIcon={isLoading ? <></> : undefined}
                    onClick={handleSubmit(async (data: Account) => {
                        if (
                            location.pathname === PATH_SIGN_UP_LOCATION ||
                            location.pathname === PATH_CONNECT_LOCATION
                        ) {
                            await handleRegisterOrConnect({
                                Password: data.Password,
                                UserName: data.UserName,
                                NFClink: linkCode ?? '',
                                TypeRegister:
                                    location.pathname === PATH_SIGN_UP_LOCATION
                                        ? TypeRegister.Register
                                        : TypeRegister.Connect,
                            });
                        } else {
                            await handleLogin(data);
                        }
                    })}
                >
                    {getNameLabelButton()}
                </LoadingButton>
            </Stack>

            <Typography>Or</Typography>
            <Typography color={color.textSecondary}>
                You already have an account?{' '}
                <span
                    onClick={handleClickNavigate}
                    style={{
                        color: color.link,
                        cursor: 'pointer',
                    }}
                >
                    {getLocationLabelHaveAnAccount()}
                </span>
            </Typography>

            <PopUpBase
                title="Confirm"
                desc={<Typography textAlign={'center'}>Log into the system right now?</Typography>}
                minWidthButton={isMediumMD ? 120 : 150}
                customActions={
                    <>
                        <Button
                            variant="cancel"
                            sx={{
                                borderRadius: '8px',
                            }}
                            onClick={() => {
                                popUpConfirmLogin.onClose();
                                navigate('/sign-in');
                            }}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            sx={{
                                justifyContent: isLoading ? 'space-between' : 'center',
                                borderRadius: '8px',
                            }}
                            disabled={disabled}
                            loading={isLoading}
                            loadingPosition="center"
                            startIcon={isLoading ? <></> : undefined}
                            onClick={handleSubmit(async (data: Account) => {
                                await handleLogin(data);
                                popUpConfirmLogin.onClose();
                            })}
                            variant="contained"
                        >
                            Confirm
                        </LoadingButton>
                    </>
                }
                {...popUpConfirmLogin}
            />
        </Stack>
    );
}
