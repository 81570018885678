import { Box, Grid, ImageList, ImageListItem, Stack, Typography } from '@mui/material';
import ListNoData from 'components/NoDataPage';
import useMedia from 'hook/useMedia';
import Image from 'mui-image';
import { PostContent } from 'submodules/nail-supplier-base-model/models/PostContent';
import color from 'theme/Colors';
import { useContent } from './ContentProvider';
interface Props {
    listItemContent: PostContent;
    index: number;
    handleOpenListMenu: (event: React.MouseEvent<HTMLElement>, id: number) => void;
}

export default function ListItemContent(props: Props) {
    const contentInfo = useContent();
    const {
        handleCloseUserMenu,
        anchorElUser,
        formatText,
        toggleExpand,
        selectedImage,
        setSelectedImage,
        closeModal,
        handleModalClick,
        handleMenuListContent,
        expanded,
    } = contentInfo;

    const { isMediumMD } = useMedia();
    const lengthImageContent = props.listItemContent.PostFiles?.length ?? 0;
    const contentText = props.listItemContent.Content;

    const openModal = (imageId: number) => {
        const selected = props?.listItemContent?.PostFiles?.find((item) => item.Id === imageId);
        if (selected) {
            setSelectedImage(selected.BlobName);
        }
    };

    const formattedText = formatText(contentText ?? '');

    const truncatedContent = formatText(contentText.substring(0, 400));
    const displayContent = expanded ? formattedText : truncatedContent;
    return (
        <Stack key={props.index} direction={'column'} spacing={3} bgcolor={color.white} borderRadius={2} padding={2}>
            <Stack
                direction={'row'}
                width={'100%'}
                paddingBottom={1}
                alignItems={'start'}
                justifyContent={'space-between'}
                spacing={isMediumMD ? 2 : 3}
                sx={{
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                }}
            >
                <Typography variant="h6" width={'max-content'}>
                    {displayContent}
                    {!expanded && contentText.length > 400 && (
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                width: 'max-content',
                            }}
                            color={color.link}
                            variant="body2"
                            onClick={toggleExpand}
                        >
                            See more
                        </Typography>
                    )}
                    {expanded && (
                        <Typography
                            sx={{
                                cursor: 'pointer',
                                width: 'max-content',
                            }}
                            color={color.link}
                            variant="body2"
                            onClick={toggleExpand}
                        >
                            Show less
                        </Typography>
                    )}
                </Typography>
                {/* <Tooltip title="Settings">
                    <IconButton
                        onClick={(e) => {
                            props.handleOpenListMenu(e, props.listItemContent.Id || 0);
                        }}
                        size="large"
                        color="primary"
                        sx={{
                            p: '4px',
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>
                </Tooltip> */}
            </Stack>
            <Grid
                sx={{
                    '&.MuiGrid-root': {
                        margin: 0,
                    },
                }}
                container
                xs={12}
            >
                {lengthImageContent > 0 ? (
                    <>
                        <Box sx={{ width: '100%', height: '100%' }}>
                            <ImageList variant="masonry" cols={3} gap={8}>
                                {props?.listItemContent?.PostFiles!.map((item) => (
                                    <ImageListItem key={item.BlobName} onClick={() => openModal(item.Id || 0)}>
                                        <Image
                                            style={{
                                                borderRadius: '8px',
                                                display: 'block',
                                                cursor: 'pointer',
                                            }}
                                            duration={1500}
                                            easing="cubic-bezier(0.7, 0, 0.6, 1)"
                                            src={`${item.BlobName}`}
                                            alt={item.BlobName}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>
                    </>
                ) : (
                    <ListNoData />
                )}
            </Grid>
        </Stack>
    );
}
