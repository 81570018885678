import { Paging } from '@Core/Paging';
import { Button, Divider, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import PictureIcon from 'assets/jsx-icon/PictureIcon';
import UploadIMG from 'assets/jsx-icon/UploadIMG';
import usePopUp, { IPopUp } from 'components/hooks/usePopUp';
import PopUpBase from 'components/PopUpBase';
import RequiredTitle from 'components/RequiredTitle';
import useMedia from 'hook/useMedia';
import { VisuallyHiddenInput } from 'pages/contentPage/PostContentPage';
import { useEffect, useState } from 'react';
import { PostContent, PostFile } from 'submodules/nail-supplier-base-model/models/PostContent';
import color from 'theme/Colors';
import { useContent } from './ContentProvider';

type Props = Omit<IPopUp, 'onConfirm'> & {
    listContent: Paging<PostContent>;
    idListContent: number;
};

export default function PopUpEditOrAddContent(props: Props) {
    const { open, onClose, idListContent } = props;

    const contentInfo = useContent();
    const {
        setContentProps,
        postOrUpdateContent,
        contentProps,
        listContent,
        handleFileChange,
        setImageUrls,
        setIdListContent,
        imageUrls,
    } = contentInfo;

    const { isMediumMD, isMobileSM } = useMedia();
    const [idImg, setIdImg] = useState<number>(1);

    const isCheckLengthContent = contentProps.Content.length;
    const [defaultContent, setDefaultContent] = useState<string>('');
    const selected = listContent.rows.find((item) => item.Id === idListContent);

    const disableButton = () => {
        if (idListContent !== 0) {
            return (
                (selected?.PostFiles?.[0]?.BlobName === contentProps.PostFiles?.[0]?.BlobName &&
                    contentProps.Content === defaultContent) ||
                contentProps.Content === ''
            );
        } else {
            return !contentProps.Files.length || contentProps.Content === defaultContent || contentProps.Content === '';
        }
    };

    const popUpRemoveImage = usePopUp();

    useEffect(() => {
        if (!open) return;

        setDefaultContent(selected?.Content || '');
        if (idListContent !== 0 || selected?.PostFiles?.length) {
            setContentProps((prev) => ({
                ...prev,
                Content: selected?.Content || '',
                PostFiles: [
                    {
                        BlobName: selected?.PostFiles?.[0].BlobName || '',
                        ContentId: 1,
                    },
                ],
            }));
        }
    }, [open]);

    // const handleRemoveImage = (idImg: number, event?: React.MouseEvent<HTMLButtonElement>) => {
    //     event?.stopPropagation();
    //     setImageUrls((prevUrls) => prevUrls.filter((url, index) => url.id !== idImg));
    //     popUpRemoveImage.onClose();
    // };
    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        minWidth: '200px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">{idListContent !== 0 ? 'Edit' : 'Add'}</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={isMediumMD ? 120 : 150}
            desc={
                <Stack bgcolor={color.white} borderRadius={1}>
                    <Stack direction={'column'} spacing={1}>
                        <RequiredTitle title={idListContent !== 0 ? 'Edit' : 'Add'} required lineHeight={0} />
                        <TextField
                            id="outlined-multiline-static"
                            autoFocus
                            error={isCheckLengthContent > 2000 ? true : false}
                            multiline
                            sx={{
                                '& .MuiInputBase-input': {
                                    padding: 0,
                                    borderRadius: 0,
                                },
                            }}
                            rows={8}
                            value={contentProps.Content}
                            onChange={(e) => setContentProps((prev) => ({ ...prev, Content: e.target.value }))}
                            placeholder="Article content"
                        />
                        <Typography
                            textAlign={'end'}
                            color={isCheckLengthContent > 2000 ? color.danger : color.textPrimary}
                        >
                            {isCheckLengthContent} / 2000
                        </Typography>
                    </Stack>

                    <Divider
                        orientation="horizontal"
                        flexItem
                        color={color.white}
                        sx={{ display: 'flex', marginY: 2 }}
                    />

                    <Stack direction={'column'} my={2} spacing={4} width={'100%'} margin={'auto'}>
                        <Stack direction={'column'} my={2} spacing={1} width={'100%'}>
                            <RequiredTitle title="Image" required lineHeight={0} />
                            <Stack
                                direction={isMobileSM ? 'column' : 'row'}
                                alignItems={isMobileSM ? 'center' : 'end'}
                                justifyContent={'space-between'}
                                p={'16px 24px'}
                                spacing={2}
                                sx={{
                                    backgroundColor: 'transparent',
                                    border: `2px dashed ${color.containedButtonBg}`,
                                    borderRadius: '8px',
                                }}
                            >
                                {imageUrls.length || idListContent !== 0 ? (
                                    <Grid item xs={7} sm={8}>
                                        <img
                                            src={
                                                idListContent !== 0
                                                    ? contentProps.PostFiles?.[0].BlobName
                                                    : imageUrls[0].url || ''
                                            }
                                            alt={`${contentProps.PostFiles?.[0].BlobName || ''}`}
                                            style={{
                                                display: 'block',
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '8px',
                                                border: '1px solid #ddd',
                                                padding: '5px',
                                            }}
                                        />
                                        {/* <IconButton
                                            sx={{
                                                position: 'absolute',
                                                top: '5px',
                                                right: '5px',
                                            }}
                                            onMouseUp={(e) => {
                                                e.stopPropagation();
                                                popUpRemoveImage.setTrue();
                                                setIdImg(url.Id || 0);
                                            }}
                                        >
                                            <RemoveImgIcon />
                                        </IconButton> */}
                                    </Grid>
                                ) : (
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <IconButton
                                            role={undefined}
                                            tabIndex={-1}
                                            sx={{
                                                alignItems: 'center',
                                                backgroundColor: color.grey200,
                                                border: `5px solid ${color.grey100}`,
                                                width: '48px',
                                                marginBottom: isMobileSM ? '0px' : '12px',
                                            }}
                                        >
                                            <UploadIMG />
                                        </IconButton>
                                        <Stack>
                                            <Typography variant="body1" color={color.textSecondary}>
                                                Upload image
                                            </Typography>
                                            <Typography variant="body2" color={color.secondary}>
                                                SVG, PNG, JPG
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                )}

                                <Stack component="label" minWidth={isMobileSM ? '100%' : '130px'}>
                                    <Button
                                        component="label"
                                        variant="outlined"
                                        sx={{
                                            minWidth: isMobileSM ? '100%' : '100px',
                                        }}
                                    >
                                        Upload image
                                        <VisuallyHiddenInput type="file" accept="image/*" onChange={handleFileChange} />
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    {/* <PopUpWarning
                        {...popUpRemoveImage}
                        onConfirm={() => {
                            handleRemoveImage(idImg);
                        }}
                        title="Delete"
                        message="Are you sure you want to delete?"
                        responsiveWidthButton={isMediumMD ? 120 : 150}
                    /> */}
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            setContentProps((prev) => ({
                                ...prev,
                                Content: '',
                                Files: [],
                            }));
                            setIdListContent(0);
                            setImageUrls([]);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={disableButton()}
                        onClick={() => {
                            if (idListContent) {
                                postOrUpdateContent(idListContent);
                            } else {
                                postOrUpdateContent();
                            }
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
