import { Button, Stack, Typography } from '@mui/material';
import ConfirmIcon from 'assets/jsx-icon/ConfirmIcon';
import ICDelete from 'assets/jsx-icon/ICDelete';
import { IPopUp } from 'components/hooks/usePopUp';
import PopUpBase from 'components/PopUpBase';
import useMedia from 'hook/useMedia';
import { useEffect } from 'react';
import color from 'theme/Colors';

type Props = Omit<IPopUp, 'onConfirm'> & {
    errorConnectFB: string;
    iserrorConnectFB: boolean;
};

export default function PopUpErrorConnectFacebook(props: Props) {
    const { open, onClose } = props;
    const { isMediumMD } = useMedia();
    useEffect(() => {
        if (!open) return;
    }, [open]);
    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        width: isMediumMD ? '300px' : '400px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack
                    p={1}
                    borderRadius={'50%'}
                    border={`3px solid ${props.iserrorConnectFB ? color.danger : color.success100}`}
                    alignItems={'center'}
                >
                    {props.iserrorConnectFB ? (
                        <ICDelete widthAndHeight={16} />
                    ) : (
                        <ConfirmIcon widthAndHeight={20} color={color.success100} />
                    )}
                </Stack>
            }
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={isMediumMD ? 120 : 150}
            desc={
                <Stack direction={'column'} spacing={1}>
                    <Typography variant="h4" fontWeight={600} width={'100%'} textAlign={'center'}>
                        {props.iserrorConnectFB ? 'Error' : 'Connected'}
                    </Typography>
                    <Typography textAlign={'center'}>
                        {!!props.iserrorConnectFB ? props.errorConnectFB : 'Connect facebook successfully!'}
                    </Typography>
                </Stack>
            }
            customActions={
                <Stack alignItems={'end'} width="100%">
                    <Button
                        variant={props.iserrorConnectFB ? 'errorOutlined' : 'outlinedGreen'}
                        onClick={async () => {
                            props.onClose?.();
                        }}
                        sx={{
                            width: '100%',
                        }}
                    >
                        Confirm
                    </Button>
                </Stack>
            }
        />
    );
}
