import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import color from 'theme/Colors';

interface Props {
    title: string;
    required?: boolean;
    sx?: TypographyProps;
    lineHeight?: number;
}
export default function RequiredTitle(props: Props) {
    return (
        <Typography sx={{ lineHeight: props.lineHeight, width: 'max-content', ...props.sx }}>
            {props.title}
            {props.required && (
                <Typography component={'span'} color={color.darkDanger}>
                    *
                </Typography>
            )}
            :
        </Typography>
    );
}
