import { Box, Button, IconButton, Menu, MenuItem, Modal, Stack, Typography } from '@mui/material';
import BaseListRender from 'components/BaseListRender';
import PopUpWarning from 'components/PopUpWarning';
import color from 'theme/Colors';
import useContentProvider, { ContentContext } from './components/ContentProvider';
import ListItemContent from './components/ListItemContent';
import PopUpEditOrAddContent from './components/PopUpEditOrAddContent';
import EditIcon from 'assets/jsx-icon/EditIcon';
import RemoveIcon from 'assets/jsx-icon/RemoveIcon';
import RemoveImgIcon from 'assets/jsx-icon/RemoveImgIcon';
import CreateIcon from 'assets/jsx-icon/CreateIcon';
import useMedia from 'hook/useMedia';

const menuListContentItems = [
    {
        id: 1,
        title: 'Edit content',
        icon: <EditIcon />,
    },
    {
        id: 2,
        title: 'Delete content',
        icon: <RemoveIcon />,
    },
];
export default function ListContentPage() {
    const contentInfo = useContentProvider();

    const {
        listContent,
        handleDeleteContent,
        popUpDeleteContent,
        isMediumMD,
        setIdListContent,
        setListContent,
        idListContent,
        popUpEditContent,
        isLoading,
        handleCloseUserMenu,
        anchorElUser,
        setAnchorElUser,
        handleMenuListContent,
        selectedImage,
        closeModal,
        handleModalClick,
    } = contentInfo;

    const handleOpenListMenu = (event: React.MouseEvent<HTMLElement>, id: number) => {
        setIdListContent(id);
        setAnchorElUser(event.currentTarget);
    };

    return (
        <ContentContext.Provider value={contentInfo}>
            <Stack direction="column" justifyContent={'space-between'} mb={3}>
                <Stack direction="column" justifyContent={'space-between'} mb={3}>
                    <Stack
                        flex={1}
                        direction={'row'}
                        my={isMediumMD ? 2 : 4}
                        alignItems={'center'}
                        spacing={isMediumMD ? 1 : 2}
                        justifyContent={'space-between'}
                    >
                        <Stack direction={'row'} gap={1.5}>
                            <Typography variant="h2">List content</Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent="space-between" mt={{ xs: 1, sm: 0 }}>
                            <Button
                                variant="contained"
                                sx={{
                                    p: '8px 16px',
                                }}
                                startIcon={<CreateIcon />}
                                onClick={() => {
                                    popUpEditContent.setTrue();
                                }}
                            >
                                {isMediumMD ? 'Create' : 'Create content'}
                            </Button>
                        </Stack>
                    </Stack>

                    <Stack mb={3}>
                        <Typography
                            textAlign={'right'}
                            variant={isMediumMD ? 'body2' : 'h6'}
                            sx={{ color: color.success }}
                        >
                            Total: {listContent.total ?? 0}
                        </Typography>
                    </Stack>
                    <BaseListRender
                        styleList={{ gap: 2 }}
                        paging={listContent}
                        renderedItem={(item, index) => (
                            <ListItemContent
                                key={index}
                                listItemContent={item}
                                index={index}
                                handleOpenListMenu={handleOpenListMenu}
                            />
                        )}
                        isLoading={isLoading}
                        setPage={(page) => {
                            setListContent((prev) => ({ ...prev, page }));
                        }}
                    />
                    <Menu
                        sx={{
                            mt: '45px',
                        }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {menuListContentItems.map((menu, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    handleMenuListContent(menu.id);
                                }}
                            >
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    spacing={3}
                                    justifyContent={'space-between'}
                                >
                                    {menu.icon}
                                    <Typography
                                        textAlign="center"
                                        color={menu.id === 2 ? color.darkDanger : color.textPrimary}
                                    >
                                        {menu.title}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Menu>
                    <Modal open={selectedImage !== null} onClose={closeModal}>
                        <Box
                            onClick={handleModalClick}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <img
                                src={selectedImage || ''}
                                alt="Selected Image"
                                style={{
                                    height: isMediumMD ? 'auto' : '90%',
                                    maxWidth: isMediumMD ? '90%' : 'auto',
                                    objectFit: isMediumMD ? 'contain' : 'cover',
                                    borderRadius: '8px',
                                    backgroundColor: color.white,
                                }}
                            />
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                }}
                                onMouseUp={(e) => {
                                    e.stopPropagation();
                                    closeModal();
                                }}
                            >
                                <RemoveImgIcon />
                            </IconButton>
                        </Box>
                    </Modal>
                </Stack>
                <PopUpWarning
                    {...popUpDeleteContent}
                    onConfirm={() => handleDeleteContent(idListContent || 0)}
                    title="Delete"
                    message="Are you sure you want to delete this article?"
                    onClose={() => {
                        setIdListContent(0);
                        popUpDeleteContent.onClose();
                    }}
                    responsiveWidthButton={isMediumMD ? 120 : 150}
                />
                <PopUpEditOrAddContent {...popUpEditContent} listContent={listContent} idListContent={idListContent} />
            </Stack>
        </ContentContext.Provider>
    );
}
