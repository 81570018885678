/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import usePopUp from 'components/hooks/usePopUp';
import { pushError } from 'components/toast';
import { appConfig } from 'configs';
import { accountController, axiosClient, fbConnectionHttpController } from 'controllers';
import { LocalStore } from 'helper/localStorage';
import { checkPermissions } from 'helper/permissions';
import { ID_TOKEN, PATH_CONNECT_LOCATION, PATH_LOGIN_LOCATION, PATH_SIGN_UP_LOCATION } from 'pages/loginPage';
import { useBackdrop } from 'providers/BackdropProvider';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Role, TypeRole } from 'submodules/nail-supplier-base-model/models/Role';

interface State {
    isAuth?: boolean;
    role: Role[];
    FbTokenValid: boolean;
    tokenFB: string;
    userName: string;
}

export const useAuthContext = () => {
    const navigate = useNavigate();
    const popUpConnectFBError = usePopUp();
    const backdrop = useBackdrop();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorConnectFB, setErrorConnectFB] = useState<string>('');
    const [iserrorConnectFB, setIsErrorConnectFB] = useState<boolean>(false);

    const [dataFromFbDev, setDataFromFbDev] = useState<any>();

    const setPartialState = (partial: Partial<State>) => setState((prev) => ({ ...prev, ...partial }));

    const [state, setState] = useState<State>({
        isAuth: false,
        role: [],
        FbTokenValid: true,
        tokenFB: '',
        userName: '',
    });
    const isCheckUIEmployee = checkPermissions(state.role, [TypeRole.Employee, TypeRole.Admin]);
    const isCheckUICustomer = checkPermissions(state.role, [TypeRole.Customer]);

    const loginWithJwt = async (jwt: string) => {
        axiosClient.defaults.headers['authorization'] = 'Bearer ' + jwt;
        setPartialState({ isAuth: false, role: [], FbTokenValid: true, tokenFB: '' });
        setIsLoading(true);
        await accountController
            .getMe()
            .then((res) => {
                setPartialState({
                    userName: res.UserName,
                    isAuth: true,
                    role: res.Roles,
                    FbTokenValid: res.FbTokenValid,
                    tokenFB: res.FbConnections?.length ? res.FbConnections[0].AccessToken : '',
                });

                const filteredCheckRolesEmployee = res.Roles?.some(
                    (role) => role.Type === TypeRole.Employee || role.Type === TypeRole.Admin
                );
                const filteredCheckRolesCustomer = res.Roles?.some((role) => role.Type === TypeRole.Customer);

                if (filteredCheckRolesEmployee) {
                    return navigate('/list-content');
                }
                if (filteredCheckRolesCustomer) {
                    return navigate('/my-shop');
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
                logout();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const logout = () => {
        LocalStore.deleteKey(ID_TOKEN);
        axiosClient.defaults.headers['authorization'] = '';
        setState({ isAuth: false, role: [], FbTokenValid: true, tokenFB: '', userName: '' });
    };

    useEffect(() => {
        const token = LocalStore.getValue(ID_TOKEN);
        if (token) {
            loginWithJwt(token as string);
        } else {
            logout();
            navigate('/sign-in');
        }
    }, []);

    const responseFacebook = async (response: any) => {
        backdrop.setTrue();
        await fbConnectionHttpController
            .connectFanpageFb({
                AccessToken: response.accessToken,
                FbId: response.id,
            })
            .then(async (res) => {
                if (res) {
                    setPartialState({
                        tokenFB: res,
                    });
                    await getNamePageFromFBDev(res);
                    setIsErrorConnectFB(false);
                    popUpConnectFBError.setTrue();
                }
            })
            .catch((err) => {
                setErrorConnectFB(err.response.data.message);
                setIsErrorConnectFB(true);
                popUpConnectFBError.setTrue();
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const getNamePageFromFBDev = async (tokenFromFb: string) => {
        await axios
            .get(`${appConfig.axios.urlApiFacebook}/me/accounts?access_token=${tokenFromFb}`)
            .then(async (res) => {
                if (res) {
                    await getAvatarPageFromFbDev(res.data.data[0]?.id, tokenFromFb);
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
            });
    };

    const getAvatarPageFromFbDev = async (pageId: number, tokenFromFb: string) => {
        await axios
            .get(
                `${appConfig.axios.urlApiFacebook}/${pageId}?fields=${appConfig.axios.getFields}&access_token=${tokenFromFb}`
            )
            .then((res) => {
                setDataFromFbDev(res.data);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            });
    };

    return {
        ...state,
        logout,
        isLoading,

        isCheckUIEmployee,
        isCheckUICustomer,

        responseFacebook,
        errorConnectFB,
        iserrorConnectFB,
        popUpConnectFBError,

        getNamePageFromFBDev,
        dataFromFbDev,
    };
};

type AuthContextType = ReturnType<typeof useAuthContext>;

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuth = () => useContext(AuthContext);
