import React from 'react';
import color from 'theme/Colors';

export default function PostArticlesIcon() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            className="icon-svg-fill"
            fill={color.grey600}
            xmlSpace="preserve"
            viewBox="0 0 111.26 122.88"
        >
            <style type="text/css"></style>
            <g>
                <path
                    className="st0"
                    d="M93.37,19.34h10.43c2.05,0,3.92,0.84,5.27,2.19c1.35,1.35,2.19,3.22,2.19,5.27v88.62 c0,2.06-0.84,3.92-2.19,5.27c-1.35,1.35-3.22,2.19-5.27,2.19H25.35c-2.05,0-3.92-0.84-5.27-2.19c-1.35-1.35-2.19-3.21-2.19-5.27 v-11.88H7.46c-2.05,0-3.92-0.84-5.27-2.19C0.84,100,0,98.13,0,96.08V7.46C0,5.4,0.84,3.54,2.19,2.19C3.54,0.84,5.4,0,7.46,0h78.45 c2.05,0,3.92,0.84,5.27,2.19c1.35,1.35,2.19,3.22,2.19,5.27V19.34L93.37,19.34L93.37,19.34z M48.45,44.5h-5.31 c-1.87,0-3.4-1.53-3.4-3.4c0-6.99-1.79-14.67,6.78-16.86c3.88-0.99,4.89,3.28,0.77,3.58c-2.52,0.18-3.01,1.81-3.09,4.27l4.35,0 c1.87,0,3.4,1.53,3.4,3.4v5.52C51.95,42.92,50.37,44.5,48.45,44.5L48.45,44.5L48.45,44.5z M32.42,44.5h-5.31 c-1.87,0-3.4-1.53-3.4-3.4c0-6.99-1.79-14.67,6.78-16.86c3.88-0.99,4.9,3.28,0.77,3.58c-2.52,0.18-3.01,1.81-3.09,4.27l4.35,0 c1.87,0,3.4,1.53,3.4,3.4v5.52C35.92,42.92,34.34,44.5,32.42,44.5L32.42,44.5L32.42,44.5z M60.47,41.35 c-1.72,0-3.11-1.39-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h6.94c1.72,0,3.11,1.39,3.11,3.11c0,1.72-1.4,3.11-3.11,3.11H60.47 L60.47,41.35z M24.59,61.06c-1.72,0-3.11-1.39-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h44.19c1.72,0,3.11,1.4,3.11,3.11 c0,1.72-1.39,3.11-3.11,3.11H24.59L24.59,61.06z M24.59,79.46c-1.72,0-3.11-1.4-3.11-3.11c0-1.72,1.39-3.11,3.11-3.11h44.19 c1.72,0,3.11,1.4,3.11,3.11c0,1.72-1.39,3.11-3.11,3.11H24.59L24.59,79.46z M24.28,103.54v11.88c0,0.29,0.12,0.56,0.32,0.75 c0.2,0.2,0.46,0.32,0.75,0.32h78.45c0.29,0,0.56-0.12,0.75-0.32c0.2-0.2,0.32-0.46,0.32-0.75V26.8c0-0.29-0.12-0.56-0.32-0.75 c-0.2-0.2-0.46-0.32-0.75-0.32H93.37v70.34c0,2.05-0.84,3.92-2.19,5.27c-1.35,1.35-3.22,2.19-5.27,2.19L24.28,103.54L24.28,103.54 L24.28,103.54z M85.91,6.39H7.46c-0.29,0-0.56,0.12-0.75,0.32c-0.2,0.2-0.32,0.46-0.32,0.75v88.62c0,0.29,0.12,0.56,0.32,0.75 c0.2,0.2,0.46,0.32,0.75,0.32h78.45c0.29,0,0.56-0.12,0.75-0.32c0.2-0.2,0.32-0.46,0.32-0.75V7.46c0-0.29-0.12-0.56-0.32-0.75 C86.47,6.51,86.2,6.39,85.91,6.39L85.91,6.39L85.91,6.39z"
                />
            </g>
        </svg>
    );
}
