import { axiosCreator } from 'components/controllers/axiosConfig';
import { appConfig } from 'configs';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AccountHttpController } from 'submodules/nail-supplier-base-model/controllers/http/AccountHttpController';
import { FbConnectionHttpController } from 'submodules/nail-supplier-base-model/controllers/http/FbConnectionHttpController';
import { LinkReviewHttpController } from 'submodules/nail-supplier-base-model/controllers/http/LinkReviewHttpController';
import { PostContentHttpController } from 'submodules/nail-supplier-base-model/controllers/http/PostContentHttpController';
import { RedirectLinkController } from 'submodules/nail-supplier-base-model/controllers/http/RedirectLinkController';
import { ShopHttpController } from 'submodules/nail-supplier-base-model/controllers/http/ShopHttpController';

const apiUrl = appConfig.gateway.backendURL;

export const axiosClient = axiosCreator({
    url: apiUrl,

    onError: (err: any) => {
        if (err.status === 401) {
            window.location.href = '/sign-in';
        }
        // if (err.status === 404) {
        //     window.location.href = '/';
        // }
        if (err.status === 500) {
            // window.location.href = '/';
            toast.error('Internal server error');
        }
    },
});
export const axiosClientWithFormData = axiosCreator({
    url: apiUrl,
    usingFromData: true,

    onError: (err: any) => {
        if (err.status === 401) {
            window.location.href = '/sign-in';
        }
        // if (err.status === 404) {
        //     window.location.href = '/';
        // }
        if (err.status === 500) {
            // window.location.href = '/';
            toast.error('Internal server error');
        }
    },
});

export const accountController = new AccountHttpController(apiUrl, axiosClient);
export const shopController = new ShopHttpController(apiUrl, axiosClient);
export const linkReviewController = new LinkReviewHttpController(apiUrl, axiosClient);
export const redirectLinkController = new RedirectLinkController(apiUrl, axiosClient);
export const fbConnectionHttpController = new FbConnectionHttpController(apiUrl, axiosClient);
export const upsertPostController = new PostContentHttpController(apiUrl, axiosClientWithFormData); // controller upsert post (config form data)
export const postContentHttpController = new PostContentHttpController(apiUrl, axiosClient);
